import React, { useState, useEffect } from 'react';

const DoctorPicker = ({ doctors, handleDoctorClick, loadingDoctors, isConfirmed }) => {
  const itemHeight = 40;
  const [loadingText, setLoadingText] = useState('');

  useEffect(() => {
    if (loadingDoctors) {
      let dotCount = 0;
      const intervalId = setInterval(() => {
        setLoadingText((prevText) => {
          dotCount += 1;
          if (dotCount > 20) {
            dotCount = 0;
            return '.';
          } else {
            return prevText + '.';
          }
        });
      }, 100);
  
      return () => clearInterval(intervalId);
    }
  }, [loadingDoctors]);

  
  const [selectedDoctorIndex, setSelectedDoctorIndex] = useState(null);

  // Create a Set to store unique doctor names
  const uniqueDoctorNames = new Set();

  // Filter the doctors array to remove duplicates and keep only unique doctors
  const uniqueDoctors = doctors.filter((doctor) => {
    if (!uniqueDoctorNames.has(doctor.name)) {
      uniqueDoctorNames.add(doctor.name); // Add the doctor's name to the Set
      uniqueDoctorNames.add(doctor.image);
      return true; // Include this doctor in the uniqueDoctors array
    }
    return false; // Skip this doctor (it's a duplicate)
  });
  
  const handleDoctorSelect = (index) => {
    setSelectedDoctorIndex(index);
    // handleDoctorClick(uniqueDoctors[index]); // Pass the unique doctor object
  };
  
  const containerHeight = uniqueDoctors.length * itemHeight;
  return (
    <div
      style={{
        height: '300px',
        width: '100%',
        cursor: 'default',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row'
      }}
    >
      {!isConfirmed ? (
        <div>

        </div>
      ) : (
        <div
        style={{
          height: '100%',
            cursor: 'default',
            display: 'flex',
            width: '100%',
            flexWrap: 'wrap',
            flexDirection: 'row'
          }}
          >
          {loadingDoctors ? (

            <div className="clock-arm-animation"></div>

          ) : (
            <div
              style={{
                height: '100%',
                cursor: 'default',
                display: 'flex',
                width: '100%',
                flexWrap: 'wrap',
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              {uniqueDoctors.map((doctor, index) => (
                <div
                  key={index}
                  id="doctor-blocks"
                  className='popout-content'
                  style={{
                    height: `auto`,
                    backgroundColor: '#fff',
                    width: 'calc(100vw / 5)',
                    display: 'flex',
                    cursor: 'default',
                    alignItems: 'center',
                    justifyContent: 'start',
                    border: 'outset',
                    borderColor: 'rgba(240, 93, 31, 0.1)',
                    background:
                      index === selectedDoctorIndex ? '#f0f0f0' : '#fff',
                    fontSize: '0.9rem',
                    margin: "10px",
                    padding: "0px"
                  }}
                  onClick={() => handleDoctorSelect(index)}
                >
                  <img src={doctor.url} alt={doctor.name} style={{ height: "200px", width: "45%", border: 'outset', borderColor: 'rgba(240, 93, 31, 0.2)', borderRight: '0px', borderLeft: '0px' }} />
                  <div style={{ display: 'grid', flexDirection: 'column', padding: '5px 0px 25px 5px', height: '100%', justifyContent: 'flex-start' }}>
                    <h2 style={{ padding: "5px", margin: '0px' }}>{doctor.name}</h2>
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '5px', display: 'flex' }}>
                    {doctor.total_offices ? (
                      doctor.total_offices.map((office, officeIndex) => (
                        <p
                          style={{ padding: "0px 5px 0px 0px", margin: '0px', fontSize: '0.8rem', fontWeight: '700' }}
                          key={officeIndex}
                        >
                          {office.name}
                        </p>
                      ))
                    ) : (
                      doctor.offices.map((office, officeIndex) => (
                        <p
                          style={{ padding: "0px 5px 0px 0px", margin: '0px', fontSize: '0.8rem', fontWeight: '700' }}
                          key={officeIndex}
                        >
                          {office}
                        </p>
                      ))
                    )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
  
};

export default DoctorPicker;
