import React, { useState } from 'react';
import './EditDoctor.css';

const EditDoctor = ({ doctor, onClose, docId, onDoctorUpdated }) => {
    const [doctorName, setDoctorName] = useState(doctor.name);
    const [imageFile, setImageFile] = useState(null);  // Use this to store the selected file
    const [loading, setLoading] = useState(false);


    const handleSaveClick = () => {
        setLoading(true);
        const csrfToken = localStorage.getItem('csrfToken');

        const formData = new FormData();
        formData.append('doctor_id', docId);

        if (doctorName !== doctor.name) {
            formData.append('doctor_name', doctorName);
        }

        if (imageFile) {
            formData.append('image', imageFile);
        }
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/doctor/edit_doctor`, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': csrfToken
            },
            body: formData
        })
        .then((response) => response.json())
        .then((data) => {
            onDoctorUpdated({
                oldName: doctor.name,
                oldUrl: doctor.url,
                newDoctor: data.doctor
            });
        })
        .catch((error) => {
            console.error('Error updating doctor:', error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleCancelClick = () => {
        setDoctorName(doctor.name);
        setImageFile(null);  // Reset the selected image file
        onClose();  // Close EditDoctor on cancel
    };

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);  // Set the selected file
    };

    const LoadingDots = () => {
        return (
            <span className="loading-dots">
                <span>.</span>
                <span>.</span>
                <span>.</span>
            </span>
        );
    };
    

    // console.log('doctorName', doctorName);
    // console.log('imageFile', imageFile);

    return (
        <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px', width: '300px' }}>
            <div>
                <div style={{ marginBottom: '10px' }}>
                    <label>
                        Name:
                        <input
                            type="text"
                            value={doctorName}
                            onChange={(e) => setDoctorName(e.target.value)}
                            style={{ width: '100%', padding: '8px', margin: '8px 0' }}
                            disabled={loading}
                        />
                    </label>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <label>
                        Image:
                        <input
                            type="file"
                            onChange={handleImageChange}
                            style={{ width: '100%', padding: '8px', margin: '8px 0' }}
                            disabled={loading}
                        />
                    </label>
                </div>
                <button onClick={handleSaveClick} style={{ padding: '8px 12px', fontSize: '14px', marginRight: '10px' }} disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                </button>
                <button onClick={handleCancelClick} style={{ padding: '8px 12px', fontSize: '14px' }} disabled={loading}>
                    Cancel
                    {loading && <LoadingDots />}
                </button>
            </div>
        </div>
    );
};

export default EditDoctor;
