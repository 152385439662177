import React, { useState, useEffect } from 'react';
import './AgeGroupMenuRefactor.css';

const AgeGroupMenuRefactor = ({ offices, bodily_parts, editBookArray, docName, setEditBookArray, manualSetDoctors, updateSelectedDoctor, errorAgeGroupCreate, setErrorAgeGroupCreate, setShowSaved, setShowAddForm, setSavingObject, setLoadingObject, loadingEditBook, setLoadingEditBook }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [locationClicked, setLocationClicked] = useState(false);
    const [locationsToSend, setLocationsToSend] = useState([]);
    const [bodilyPartsClicked, setBodilyPartsClicked] = useState(false);
    const [bodilyPartsToSend, setBodilyPartsToSend] = useState([]);
    const [saving, setSaving] = useState(false);
    const [keyTwo, setKeyTwo] = useState(0);
    const [name, setName] = useState(''); // State for the name
    const [minAge, setMinAge] = useState(18); // State for the min age
    const [maxAge, setMaxAge] = useState(65); // State for the max age
    const [errorAgeGroup, setErrorAgeGroup] = useState('');
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        setKeyTwo(prevKey => prevKey + 1);
    }, [editBookArray]);
    
    // useEffect(() => {
    //     console.log('AgeGroupMenuRefactor Props:', {
    //         offices,
    //         bodily_parts,
    //         editBookArray,
    //         docName,
    //         setEditBookArray,
    //         manualSetDoctors,
    //         updateSelectedDoctor,
    //         errorAgeGroupCreate,
    //         setErrorAgeGroupCreate,
    //         setShowSaved,
    //         loadingEditBook
    //     });
    
    //     console.log('AgeGroupMenuRefactor State:', {
    //         selectedItem,
    //         locationClicked,
    //         locationsToSend,
    //         bodilyPartsClicked,
    //         bodilyPartsToSend,
    //         saving,
    //         keyTwo,
    //         name,
    //         minAge,
    //         maxAge,
    //         errorAgeGroup
    //     });
    // }, [offices, bodily_parts, editBookArray, docName, setEditBookArray, manualSetDoctors, updateSelectedDoctor, errorAgeGroupCreate, setErrorAgeGroupCreate, setShowSaved, loadingEditBook, selectedItem, locationClicked, locationsToSend, bodilyPartsClicked, bodilyPartsToSend, saving, keyTwo, name, minAge, maxAge, errorAgeGroup]);
    

    const handleSave = () => {
        setSaving(true);
        setLoading(true);
        const bodilyParts = bodilyPartsToSend.map(part => part.item);
        const locations = locationsToSend.map(location => location.item);
    
        const addData = {
            minAge: minAge,
            maxAge: maxAge,
            bodilyParts: bodilyParts,
            locations: locations,
            doctor_name: docName,
            search_name: name
        };
    
        const csrfToken = localStorage.getItem('csrfToken');

        // const existingBook = editBookArray.find(book =>
        //     book.title === 'Age Groups' && book.lines.some(line => line.name === name)
        // );
        
        // if (existingBook) {
        //     setErrorAgeGroupCreate(`Age Group Name '${name}' Already Exists in '${existingBook.name}' (Range: ${existingBook.range.min}-${existingBook.range.max})`);
        //     console.log('existingBook', existingBook);
        //     setLoading(false);
        //     setSaving(false);
        //     setSavingObject(false);
        //     setLoadingObject(false);
        //     // setLoadingEditBook(false);
        //     return;
        // }
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/doctor/add_age_group`, {
            method: 'POST',
            body: JSON.stringify({ data: addData }), // Wrap the data in an object
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            }
        })
        .then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    console.log('response /add_age_group', data);
                    if (response.message) {
                        console.error('Error 1 /add_age_group');         
                        setLoading(false);
                        setSaving(false);
                        setSavingObject(false);
                        setLoadingObject(false);
                        // setLoadingEditBook(false);
                        setErrorAgeGroup(response.message);
                    } else {
                            
                        let newAgeGroup = data.doctor.age_groups.find((ageGroup) =>
                            !editBookArray.some((book) =>
                                book.title === 'Age Groups' && book.lines.some((line) => line.name === ageGroup.name)
                            )
                        );
                        const bookArray = [
                            {
                                title: 'Age Groups',
                                lines: data.doctor.age_groups,
                                icon: 'fa-calendar-alt'
                            },
                            {
                                title: 'Body Parts',
                                lines: data.doctor.bodily_parts,
                                icon: 'fa-universal-access'
                            },
                            {
                                title: 'Insurances',
                                lines: data.doctor.insurances,
                                icon: 'fa-file-contract'
                            },
                            {
                                title: 'Offices',
                                lines: data.doctor.offices,
                                icon: 'fa-building'
                            }
                        ]
                        if (newAgeGroup) {
                            // Add the new age group to editBookArray
                            const updatedAgeGroup = {
                                name: newAgeGroup.name,
                                relation: {
                                    locations: newAgeGroup.locations,
                                    body_parts: newAgeGroup.body_parts
                                }
                            };
                            const updatedBookArray = editBookArray.map((book) => {
                                if (book.title === 'Age Groups') {
                                    return {
                                        ...book,
                                        lines: [...book.lines, updatedAgeGroup]
                                    };
                                }
                                return book;
                            });
                            setEditBookArray(updatedBookArray);
                        }
            
                        setSaving(false);
                        setLoading(false);
                        setSavingObject(false);
                        setLoadingObject(false);
                        // setLoadingEditBook(false);

            
                        const updatedBookArray = editBookArray.map((book) => {
                            if (book.title === 'Age Groups') {
                                const updatedLines = book.lines.map((line) => {
                                    const ageGroup = data.doctor.age_groups.find((ag) => ag.name === line.name);
                                    if (ageGroup) {
                                        return {
                                            ...line,
                                            relation: {
                                                ...line.relation,
                                                locations: ageGroup.locations,
                                                body_parts: ageGroup.body_parts
                                            }
                                        };
                                    }
                                    return line;
                                });
            
                                data.doctor.age_groups.forEach((ageGroup) => {
                                    const existingLine = updatedLines.find((line) => line.name === ageGroup.name);
                                    if (!existingLine) {
                                        updatedLines.push({
                                            name: ageGroup.name,
                                            covered: true,
                                            relation: {
                                                age_group_id: ageGroup.id,
                                                locations: ageGroup.locations,
                                                body_parts: ageGroup.body_parts,
                                                doctor_id: data.doctor.id
                                            },
                                            range: ageGroup.range,

                                        });
                                    }
                                });
            
                                return {
                                    ...book,
                                    lines: updatedLines
                                };
                            }
                            return book;
                        });
            

                        const forManualDoctors = data.doctor.doctors_scheduler;
                        setEditBookArray(updatedBookArray);
                        updateSelectedDoctor(data);
                        setLocationsToSend([]);
                        setBodilyPartsToSend([]);
                        manualSetDoctors(forManualDoctors);
                        setLoading(false);
                        setSaving(false);
                        setShowSaved(true); // Show the "Saved" message
                        setShowAddForm(false);
                        setTimeout(() => {
                            setShowSaved(false); // Hide the "Saved" message after 1 second
                        }, 1500);
                    }    
                });
            } else {
                response.json().then((data) => {
                    console.log('response not ok response.json data', data);
                    if (data.message) {
                        console.error('Error 2 /add_age_group');
                        // console.log('Current Loading State:', loading);
                        // console.log('Current Error State for Age Group Creation:', errorAgeGroupCreate);
                        // console.log('Current Error State for Age Group:', errorAgeGroup);
                        // console.log('Is Saving Active:', saving);            
                        setSaving(false);
                        setLoading(false);
                        setSavingObject(false);
                        setLoadingObject(false);
                        // setLoadingEditBook(false);
                        // setErrorAgeGroup(data.message);
                        setErrorAgeGroupCreate(data.message);
                    } else {
                        console.error('Error 3 /add_age_group');
                        // console.log('Current Loading State:', loading);
                        // console.log('Current Error State for Age Group Creation:', errorAgeGroupCreate);
                        // console.log('Current Error State for Age Group:', errorAgeGroup);
                        // console.log('Is Saving Active:', saving);          

                    }
                })
            }
        })
        
        
        
        .catch((error) => {
            console.error('Error /add_age_group', error);
        });
        
    };

    const handleLocationClicked = (item, task) => {
        const isDuplicate = locationsToSend.some(location => location.item === item);
        if (!isDuplicate) {
            if (task === 'Add OFfice') {
                const updatedLocations = locationsToSend.filter((loc) => loc !== item);
                setLocationsToSend(updatedLocations);
            } else if (task === 'Remove Office') {
                const updatedLocations = locationsToSend.filter((loc) => loc !== item);
                setLocationsToSend(updatedLocations);
            } else {
                setLocationsToSend(prevLocations => [
                    ...prevLocations,
                    {
                        task: task,
                        item: item
                    }
                ]);
            }
        }
    };
    const handleBodilyPartClicked = (item, task) => {
        const isDuplicate = bodilyPartsToSend.some(bodil_part => bodil_part.item === item);
        if (!isDuplicate) {
            if (task === 'Add Bodily Part') {
                const updatedBodilyParts = bodilyPartsToSend.filter((bod) => bod !== item);
                setBodilyPartsToSend(updatedBodilyParts);
            } else if (task === 'Remove Bodily Part') {
                const updatedBodilyParts = bodilyPartsToSend.filter((bod) => bod !== item);
                setBodilyPartsToSend(updatedBodilyParts);
            } else {
                setBodilyPartsToSend(prevBodilyParts => [
                    ...prevBodilyParts,
                    {
                        task: task,
                        item: item
                    }
                ]);
            }
        }
    };

    const handleItemClick = (item) => {
        if (selectedItem === item) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    if (loading) {
        return (
            <div className="clock-arm-animation"></div>
        );
    } else {
        return (
            <div style={{ border: '1px solid black', padding: '5px 10px 15px 10px' }} className="refactored-dropdown-age-group" key={keyTwo}>
                <div className="refactored-dropdown-age-group-content">
                    <div style={{ width: '100%' }} className="refactored-column-age-group">
                            {/* name, min, max, save */}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className="name-input-and-min-max-responsive">
                                        {/* Age Group Name And Input */}
                                        <div className='name-input-responsive'>
                                            <p style={{ fontSize: '0.9rem', lineHeight: '20px', margin: '0px 5px 0px 0px', padding: '0px 0px 0px 0px', width: 'max-content', color: 'black', textShadow: 'none' }}
                                            >
                                                Age Group Name:
                                            </p>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <input
                                                    onChange={(e) => {
                                                        setName(e.target.value);
                                                        setErrorAgeGroupCreate(null);
                                                        setErrorAgeGroup('');
                                                    }}
                                                    className="input-responsive"
                                                    type="text"
                                                    placeholder='Name'
                                                    value={name} 
                                                    />
                                                {errorAgeGroup && <p style={{ color: 'red', fontSize: '0.8rem', textShadow: 'none', margin: '0px 0px 0px 0px', padding: '2px 0px 2px 0px' }}>{errorAgeGroup}</p>}
                                            </div>
                                        </div>
                                        
                                        <div className="min-max-responsive">
                                            {/* Age Group Min Age */}
                                            <div style={{ margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', display: 'flex', flexDirection: 'row' }}>
                                                <p style={{ fontSize: '0.9rem', lineHeight: '20px', margin: '0px 5px 0px 0px', padding: '0px 0px 0px 0px', width: 'max-content', color: 'black', textShadow: 'none' }}>Minimun Age:</p> <input style={{ display: 'flex', height: 'min-content', margin: '0px 3px 0px 0px', width: '75%', overflow: 'hidden', lineHeight: '30px', justifyContent: 'center', alignItems: 'center' }} type="number" placeholder="18" value={minAge} onChange={(e) => setMinAge(parseInt(e.target.value))} />
                                            </div>
    
                                            {/* Age Group Max Age */}
                                            <div style={{ margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', display: 'flex', flexDirection: 'row' }}>
                                                <p style={{ fontSize: '0.9rem', lineHeight: '20px', margin: '0px 5px 0px 0px', padding: '0px 0px 0px 0px', width: 'max-content', color: 'black', textShadow: 'none' }}>Maximum Age:</p> <input style={{ display: 'flex', height: 'min-content', margin: '0px 3px 0px 0px', width: '75%', overflow: 'hidden', lineHeight: '30px', justifyContent: 'center', alignItems: 'center' }} type="number" placeholder="65" value={maxAge} onChange={(e) => setMaxAge(parseInt(e.target.value))} />
                                            </div>
                                        </div>
                                    </div>
                                    {/* Age Group Button */}
                                    <div style={{ margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', minWidth: '130px', lineHeight: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                        <button onClick={() => {
                                            // console.log('name', name);
                                            // console.log('locationsToSend', locationsToSend);
                                            // console.log('bodilyPartsToSend', bodilyPartsToSend);
                                                if (name) {
                                                    if (locationsToSend) {
                                                        if (bodilyPartsToSend) {
                                                            // console.log('minAge', minAge);
                                                            // console.log('maxAge', maxAge);
                                                            if (minAge) {
                                                                if (maxAge) {
                                                                    setSelectedItem({
                                                                        name,
                                                                        range: {
                                                                            min: minAge,
                                                                            max: maxAge
                                                                        }
                                                                    });
                                                                    handleSave();
                                                                } else {
                                                                    alert('Please choose a Maximum Age');
                                                                }
                                                            } else {
                                                                alert('Please choose a Minimum Age');
                                                            }
                                                        } else {
                                                            alert('Please choose a Body Part');
                                                        }
                                                    } else {
                                                        alert('Please choose an Office');
                                                    }
                                                } else {
                                                    alert('Please fill in Age Group Name');
                                                }
                                            }}>
                                                Add Age Group
                                        </button>
                                    </div>
                                </div>
                            </div>
    
                        {/* bodily parts/chosen/offices */}
                        <div className="refactored-age-group-form">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
    
                                    {/* bodily parts */}
                                    <ul className="refactored-horizontal-scroll" style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-start', flexDirection: 'column', flexFlow: 'column', overflowX: 'hidden', minWidth: '180px', height: 'inherit', padding: '0px', margin: '0px' }}>
                                        {bodily_parts
                                            .map((body_part, index) => (
                                                <li key={index} onClick={() => handleBodilyPartClicked(body_part.name, 'Add Part')} className='age-group-list-add'>
                                                <span style={{ color: 'green', marginLeft: '5px' }}>+</span>
                                                    
                                                    {body_part.name}
                                                </li>
                                            ))
                                        }
                                        
                                    </ul>
                                    {/* chosen */}
                                    <div style={{ border: '1px solid black', width: '40%', margin: '10px 0px 0px 0px' }} className="refactored-horizontal-scroll">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-around', backgroundColor: 'lightgrey', minWidth: '100%' }}>
                                            <div className="list-to-split">
                                                <div className="edit-group-list">
                                                    <p style={{ padding: '0px 5px 0px 5px', margin: '0px 0px 0px 0px', fontSize: '0.9rem' }}> Age Group:</p>
                                                    <p 
                                                        style={{ padding: '0px 5px 0px 5px', margin: '0px 0px 0px 0px', maxWidth: '200px', fontSize: `${Math.max(10, 20 - name.length + 15)}px` }}
                                                    >{name}</p>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <p className="age-group-numbers-responsive" >({minAge}</p>
            
                                                    <p className="age-group-numbers-responsive" >
                                                        -
                                                    </p>
                                                    <p className="age-group-numbers-responsive">{maxAge})</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <div style={{ display: 'flex', width: '50%', borderRight: '0.25px solid lightgrey', padding: '0px 0px 0px 0px',  alignSelf: 'flex-start', justifySelf: 'flex-start', flexDirection: 'column' }}>
                                                {bodilyPartsToSend && bodilyPartsToSend.map((bodily_part, index) => (
                                                    <li key={index}
                                                    onClick={() => handleBodilyPartClicked(bodily_part, 'Remove Bodily Part')}
                                                    className={`refactored-age-group-list-remove ${bodilyPartsToSend.some(part => part.item === bodily_part) ? 'selected' : ''}`}
                                                    >
                                                        <span style={{ color: 'green', marginRight: '5px' }}>+</span>
                                                        {bodily_part.item}
                                                    </li>
                                                ))}
                                            </div>    
                                            <div style={{ display: 'flex', width: '50%', borderLeft: '0.25px solid lightgrey', padding: '0px 0px 0px 0px',  alignSelf: 'flex-start', justifySelf: 'flex-start', flexDirection: 'column' }}>
                                                {locationsToSend && locationsToSend.map((location, index) => (
                                                    <li key={index}
                                                    onClick={() => handleLocationClicked(location, 'Remove Office')}
                                                    className={`refactored-age-group-list-remove ${locationsToSend.some(part => part.item === location) ? 'selected' : ''}`}
                                                    >
                                                        <span style={{ color: 'green', marginRight: '5px' }}>+</span>
                                                        {location.item}
                                                    </li>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/* offices */}
                                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '250px' }}>
                                        <ul className="refactored-horizontal-scroll" style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-start', flexDirection: 'column', flexFlow: 'column', overflowX: 'hidden', minWidth: '190px', height: 'inherit', padding: '0px', margin: '0px' }}>
                                        {offices
                                            .sort((a, b) => a.localeCompare(b))
                                            .map((office, index) => (
                                                    <li key={index} onClick={() => handleLocationClicked(office, 'Add Office')} className='age-group-list-add'>
                                                        <span style={{ color: 'green', marginLeft: '5px' }}>
                                                            +
                                                        </span>
                                                        {office}
                                                    </li>
                                                ))
                                            }
                                            
                                        </ul>
                                    </div>
                                </div>
    
                        </div>
    
                    </div>
                </div>
            </div>
        );
    }
};

export default AgeGroupMenuRefactor;


