import React, { useState } from 'react';
import './ApplyAgeGroupForm.css';

const ApplyAgeGroupForm = ({ i, item, selectedItem, setErrorAgeGroupCreate, docName, editBookArray, setShowSaved, setEditBookArray, updateSelectedDoctor,manualSetDoctors, setSelectedItem, bodily_parts, offices, setSavingObject, setLoadingObject, loadingEditBook, setLoadingEditBook }) => {

    const [locationClicked, setLocationClicked] = useState(false);
    const [locationsToSend, setLocationsToSend] = useState([]);
    const [bodilyPartsClicked, setBodilyPartsClicked] = useState(false);
    const [bodilyPartsToSend, setBodilyPartsToSend] = useState([]);
    const [saving, setSaving] = useState(false);
    const [keyTwo, setKeyTwo] = useState(0);
    const [name, setName] = useState(item.name); // State for the name
    const [minAge, setMinAge] = useState(item.range.min); // State for the min age
    const [maxAge, setMaxAge] = useState(item.range.max); // State for the max age
    const [loading, setLoading] = useState(false);

    const handleSave = () => {
        setSaving(true);
        setLoading(true);
        const bodilyParts = bodilyPartsToSend.map(part => part.item);
        const locations = locationsToSend.map(location => location.item);
    
        const addData = {
            minAge: minAge,
            maxAge: maxAge,
            bodilyParts: bodilyParts,
            locations: locations,
            doctor_name: docName,
            search_name: item.name,
            typed_name: name
        };
    
        const csrfToken = localStorage.getItem('csrfToken');

        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/doctor/apply_age_group`, {
            method: 'POST',
            body: JSON.stringify({ data: addData }), // Wrap the data in an object
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            }
        })
        .then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    console.log('response /apply_age_group', data);
                    // console.log('editBookArray', editBookArray);
                    setSaving(false);
                    setSavingObject(false);
                    setLoadingObject(false);
                    setLoadingEditBook(false);

                    setEditBookArray(null);
                    setSelectedItem(null); // Clear selectedItem if the group is found

                    let updatedBookArray = null;
                    updatedBookArray = editBookArray.map((book) => {
                        if (book.title === 'Age Groups') {
                            const updatedLines = book.lines.map((line) => {
                                if (line.name === selectedItem.name) {
                                    const ageGroup = data.doctor.age_groups.find((ag) => ag.name === line.name);
                                    // console.log('ageGroup', ageGroup);
                                    if (ageGroup) {
                                        return {
                                            ...line,
                                            relation: ageGroup.relation,
                                            locations: ageGroup.relation.locations,
                                            body_parts: ageGroup.relation.body_parts,
                                            covered: true
                                        };
                                    }
                                }
                                if (line.relation) {
                                    return {
                                        ...line,
                                        relation: {
                                            ...line.relation,
                                        },
                                        locations: line.relation.locations,
                                        body_parts: line.relation.body_parts,
                                        covered: true
                                    };
                                } else {
                                    return line;
                                }

                            });
                            // console.log('updatedLines', updatedLines);
                            return {
                                ...book,
                                lines: updatedLines
                            };
                        } else if (book.title === 'Body Parts') {
                            const updatedLines = book.lines.map((line) => {
                                const bodily_part = data.doctor.bodily_parts.find((ag) => ag.name === line.name);
                                if (bodily_part) {
                                    return {
                                        ...line,
                                        covered: true,
                                        
                                    };
                                }
                                return {
                                    ...line,
                                    covered: false,
                                    
                                };
                            });

                            data.doctor.bodily_parts.forEach((bodily_part) => {
                                const existingLine = updatedLines.find((line) => line.name === bodily_part.name);

                                if (!existingLine) {
                                    updatedLines.push({
                                        name: bodily_part.name,
                                        id: bodily_part.id,
                                        covered: true
    
                                    });
                                }
                            });
                            
                            return {
                                ...book,
                                lines: updatedLines
                            };

                        } else if (book.title === 'Insurances') {
                            const updatedLines = book.lines.map((line) => {
                                const insurance = data.doctor.insurances.find((ag) => ag.name === line.name);
                                if (insurance) {
                                    return {
                                        ...line,
                                        covered: true,
                                        
                                    };
                                }
                                return {
                                    ...line,
                                    covered: false,
                                    
                                };
                            });                        
                            data.doctor.insurances.forEach((insurance) => {
                                const existingLine = updatedLines.find((line) => line.name === insurance.name);
                                if (!existingLine) {
                                    updatedLines.push({
                                        name: insurance.name,
                                        id: insurance.id,
                                        covered: true
    
                                    });
                                }
                            });
                            
                            return {
                                ...book,
                                lines: updatedLines
                            };
                        } else if (book.title === 'Offices') {
                            const updatedLines = book.lines.map((line) => {
                                const office = data.doctor.offices.find((ag) => ag.name === line.name);
                                if (office) {
                                    return {
                                        ...line,
                                        covered: true,
                                        
                                    };
                                }
                                return {
                                    ...line,
                                    covered: false,
                                    
                                };
                            });
                            data.doctor.offices.forEach((office) => {
                                const existingLine = updatedLines.find((line) => line.name === office.name);
    
                                if (!existingLine) {
                                    updatedLines.push({
                                        name: office.name,
                                        id: office.id,
                                        covered: true
    
                                    });
                                }
                            });
                            
                            return {
                                ...book,
                                lines: updatedLines
                            };
                        }
                        console.log('not here');
                        return book;
                    });

                    const updatedSelectedItem = data.doctor.age_groups.find((ag) => ag.name === selectedItem.name);

                    if (updatedSelectedItem) {
                        setSelectedItem(null); // Clear selectedItem if the group is found

                        // setSelectedItem((prevSelectedItem) => {
                        //     const updatedItem = { ...prevSelectedItem };
                        //     console.log('updatedItem', updatedItem);
                        //     console.log('updatedSelectedItem', updatedSelectedItem);

                        //     const index = updatedSelectedItem.relation.age_group_id;
                        //     updatedItem.relation = {
                        //         id: index,
                        //         locations: updatedSelectedItem.relation.locations,
                        //         body_parts: updatedSelectedItem.relation.body_parts
                        //     }
                        //     return updatedItem;
                        // });
                    }
                    
                    const forManualDoctors = data.doctor.doctors_scheduler;
                    // console.log('updatedBookArray', updatedBookArray);
                    setEditBookArray(updatedBookArray);
                    updateSelectedDoctor(data);
                    setLocationsToSend([]);
                    setBodilyPartsToSend([]);


                    manualSetDoctors(forManualDoctors);
                    setShowSaved(true); // Show the "Saved" message
                    setLoading(false);
                    setLoadingObject(false);
                    setLoadingEditBook(false);
                    setTimeout(() => {
                        setShowSaved(false); // Hide the "Saved" message after 1 second
                    }, 1500);
                });
            }
        })
        
        
        
        .catch((error) => {
            console.error('Error /apply_age_group', error);
            setLoading(false);
            setLoadingObject(false);
            setLoadingEditBook(false);


        });
        
    };
    const handleLocationClicked = (item, task) => {
        const isDuplicate = locationsToSend.some(location => location.item === item);
        if (!isDuplicate) {
            if (task === 'Add OFfice') {
                const updatedLocations = locationsToSend.filter((loc) => loc !== item);
                setLocationsToSend(updatedLocations);
            } else if (task === 'Remove Office') {
                const updatedLocations = locationsToSend.filter((loc) => loc !== item);
                setLocationsToSend(updatedLocations);
            } else {
                setLocationsToSend(prevLocations => [
                    ...prevLocations,
                    {
                        task: task,
                        item: item
                    }
                ]);
            }
        }
    };
    const handleBodilyPartClicked = (item, task) => {
        const isDuplicate = bodilyPartsToSend.some(bodil_part => bodil_part.item === item);
        if (!isDuplicate) {
            if (task === 'Add Bodily Part') {
                const updatedBodilyParts = bodilyPartsToSend.filter((bod) => bod !== item);
                setBodilyPartsToSend(updatedBodilyParts);
            } else if (task === 'Remove Bodily Part') {
                const updatedBodilyParts = bodilyPartsToSend.filter((bod) => bod !== item);
                setBodilyPartsToSend(updatedBodilyParts);
            } else {
                setBodilyPartsToSend(prevBodilyParts => [
                    ...prevBodilyParts,
                    {
                        task: task,
                        item: item
                    }
                ]);
            }
        }
    };

    if (loading) {
        return (
            <div className="clock-arm-animation"></div>
        );
    } else {
        return (
            <div style={{ border: '1px solid black', padding: '5px 10px 15px 10px' }} className="apply-dropdown-age-group">
                <div className="apply-dropdown-age-group-content">
                    <div style={{ width: '100%' }} className="apply-age-group-column">
                        {/* name, min, max, save */}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                {/* Age Group Input */}
                                <div className="apply-name-input-responsive">
                                    <p style={{ fontSize: '0.9rem', lineHeight: '20px', margin: '0px 5px 0px 0px', padding: '0px 0px 0px 0px', width: 'max-content', color: 'black', textShadow: 'none' }}
                                    >
                                        Age Group Name:
                                    </p>
                                    <input
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            setName(e.target.value || item.name);
                                            setErrorAgeGroupCreate(null);
                                            
                                        }}
                                        className='apply-input-responsive'
                                        type="text"
                                        placeholder={name || item.name}
                                        value={name || item.name}
                                    />
                                </div>
                                {/* Age Group Min */}
                                <div style={{ margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', display: 'flex', flexDirection: 'row' }}>
                                    <p style={{ fontSize: '0.9rem', lineHeight: '20px', margin: '0px 5px 0px 0px', padding: '0px 0px 0px 0px', width: 'max-content', color: 'black', textShadow: 'none' }}>Minimun Age:</p> <input style={{ display: 'flex', height: 'min-content', margin: '0px 3px 0px 0px', width: '75%', overflow: 'hidden', lineHeight: '30px', justifyContent: 'center', alignItems: 'center' }} type="number" value={minAge} onChange={(e) => setMinAge(parseInt(e.target.value))} />
                                </div>
                                {/* Age Group Max */}
                                <div style={{ margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', display: 'flex', flexDirection: 'row' }}>

                                    <p style={{ fontSize: '0.9rem', lineHeight: '20px', margin: '0px 5px 0px 0px', padding: '0px 0px 0px 0px', width: 'max-content', color: 'black', textShadow: 'none' }}>Maximum Age:</p> <input style={{ display: 'flex', height: 'min-content', margin: '0px 3px 0px 0px', width: '75%', overflow: 'hidden', lineHeight: '30px', justifyContent: 'center', alignItems: 'center' }} type="number" value={maxAge} onChange={(e) => setMaxAge(parseInt(e.target.value))} />
                                </div>
                                {/* Age Group Button */}
                                <div style={{ margin: '0px 0px 0px 0px', padding: '0px 0px 0px 0px', minWidth: '130px', lineHeight: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                                    <button onClick={() => {
                                        // console.log('name', name);
                                        // console.log('locationsToSend', locationsToSend);
                                        // console.log('bodilyPartsToSend', bodilyPartsToSend);

                                            if (name) {
                                                if (locationsToSend) {
                                                    if (bodilyPartsToSend) {

                                                        setSelectedItem({
                                                            name,
                                                            range: {
                                                                min: minAge,
                                                                max: maxAge
                                                            }
                                                        });
                                                        handleSave();
                                                    } else {
                                                        alert('Please choose a Body Part');
                                                    }
                                                } else {
                                                    alert('Please choose an Office');
                                                }
                                            } else {
                                                alert('Please fill in Age Group Name');
                                            }
                                        }}>
                                            Apply Age Group
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="apply-age-group-form">
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>

                                    {/* bodily parts */}
                                    <ul className="apply-horizontal-scroll" style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-start', flexDirection: 'column', flexFlow: 'column', overflowX: 'hidden', minWidth: '180px', height: 'inherit', padding: '0px', margin: '0px' }}>
                                        {bodily_parts
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((body_part, index) => (
                                                <li key={index} onClick={() => handleBodilyPartClicked(body_part.name, 'Add Part')} className='age-group-list-add'>
                                                <span style={{ color: 'green', marginLeft: '5px' }}>+</span>
                                                    
                                                    {body_part.name}
                                                </li>
                                            ))
                                        }
                                        
                                    </ul>
                                    {/* chosen */}
                                    <div style={{ border: '1px solid black', width: '40%', margin: '10px 0px 0px 0px' }} className="apply-horizontal-scroll">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-around', backgroundColor: 'lightgrey', minWidth: '100%' }}>
                                            <div className="list-to-split">
                                                <div className="edit-group-list">
                                                    <p style={{ padding: '0px 5px 0px 5px', margin: '0px 0px 0px 0px', fontSize: '0.9rem' }}> Age Group:</p>
                                                    <p 
                                                        style={{ padding: '0px 5px 0px 5px', margin: '0px 0px 0px 0px', maxWidth: '200px', fontSize: `${Math.max(10, 20 - name.length + 15)}px` }}
                                                        >{name || item.name}</p>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <p style={{ padding: '0px 5px 0px 5px', margin: '0px 0px 0px 0px' }}>({minAge || item.range.min}</p>

                                                    <p style={{ padding: '0px 5px 0px 5px', margin: '0px 0px 0px 0px' }}>
                                                        -
                                                    </p>
                                                    <p style={{ padding: '0px 10px 0px 10px', margin: '0px 0px 0px 0px' }}>{maxAge || item.range.max})</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <div style={{ display: 'flex', width: '50%', borderRight: '0.25px solid lightgrey', padding: '0px 0px 0px 0px',  alignSelf: 'flex-start', justifySelf: 'flex-start', flexDirection: 'column' }}>
                                            {bodilyPartsToSend && bodilyPartsToSend
                                                .sort((a, b) => a.item.localeCompare(b.item))
                                                .map((bodily_part, index) => (
                                                    <li key={index}
                                                    onClick={() => handleBodilyPartClicked(bodily_part, 'Remove Bodily Part')}
                                                    className={`apply-age-group-list-remove ${bodilyPartsToSend.some(part => part.item === bodily_part) ? 'selected' : ''}`}
                                                    >
                                                        <span style={{ color: 'green', marginRight: '5px' }}>+</span>
                                                        {bodily_part.item}
                                                    </li>
                                                ))}
                                            </div>    
                                            <div style={{ display: 'flex', width: '50%', borderLeft: '0.25px solid lightgrey', padding: '0px 0px 0px 0px',  alignSelf: 'flex-start', justifySelf: 'flex-start', flexDirection: 'column' }}>
                                            {locationsToSend && locationsToSend
                                                .sort((a, b) => a.item.localeCompare(b.item))
                                                .map((location, index) => (
                                                    <li key={index}
                                                    onClick={() => handleLocationClicked(location, 'Remove Office')}
                                                    className={`apply-age-group-list-remove ${locationsToSend.some(part => part.item === location) ? 'selected' : ''}`}
                                                    >
                                                        <span style={{ color: 'green', marginRight: '5px' }}>+</span>
                                                        {location.item}
                                                    </li>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    {/* offices */}
                                    <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '250px' }}>
                                        <ul className="apply-horizontal-scroll" style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-start', flexDirection: 'column', flexFlow: 'column', overflowX: 'hidden', minWidth: '190px', height: 'inherit', padding: '0px', margin: '0px' }}>
                                            {offices
                                                .sort((a, b) => a.localeCompare(b))
                                                .map((office, index) => (
                                                    <li key={index} onClick={() => handleLocationClicked(office, 'Add Office')} className='age-group-list-add'>
                                                        <span style={{ color: 'green', marginLeft: '5px' }}>
                                                            +
                                                        </span>
                                                        {office}
                                                    </li>
                                                ))
                                            }
                                            
                                        </ul>
                                    </div>
                                </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ApplyAgeGroupForm;