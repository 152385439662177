import React, { useState } from 'react';
import './NewOffice.css';

const NewOffice = ({ setShowSaved, selectedDoctor, setEditBookArray, editBookArray, manualSetDoctors, setSelectedItem }) => {
    const [newOffice, setNewOffice] = useState('');
    const [errorOffice, setErrorOffice] = useState('');
    const [locationsToSend, setLocationsToSend] = useState([]);
    const [bodilyPartsToSend, setBodilyPartsToSend] = useState([]);

    const csrfToken = localStorage.getItem('csrfToken');
    const handleChange = (event) => {
        setErrorOffice('');
        setNewOffice(event.target.value);
    };
    const handleClick = () => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/new_office`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            body: JSON.stringify({ doctor_id: selectedDoctor.doctor.id, office: newOffice })
        })
        .then((response) => response.json())
        .then((responseJson) => {
            console.log('response /new_office', responseJson);
            if (responseJson.message) {
                console.error('Error 1 /new_office');
                setErrorOffice(responseJson.message);
            } else {
                const bookArray = [
                    {
                        title: 'Age Groups',
                        lines: responseJson.doctor.age_groups,
                        icon: 'fa-calendar-alt'
                    },
                    {
                        title: 'Body Parts',
                        lines: responseJson.doctor.bodily_parts,
                        icon: 'fa-universal-access'
                    },
                    {
                        title: 'Insurances',
                        lines: responseJson.doctor.insurances,
                        icon: 'fa-file-contract'
                    },
                    {
                        title: 'Offices',
                        lines: responseJson.doctor.offices,
                        icon: 'fa-building'
                    }
                ]
                

                const forManualDoctors = responseJson.doctor.doctors_scheduler;
                
                const updatedBookArray = editBookArray.map((book) => {
                    if (book.title === 'Offices') {
                        const updatedLines = book.lines.map((line) => {
                            const office = responseJson.doctor.offices.find((ag) => ag.name === line.name);
                            if (office) {
                                return {
                                    ...line,
                                    covered: true,
                                    
                                };
                            }
                            return line;
                        });
                        
                        responseJson.doctor.offices.forEach((office) => {
                            const existingLine = updatedLines.find((line) => line.name === office.name);
                            if (!existingLine) {
                                updatedLines.push({
                                    name: office.name,
                                    id: office.id,
                                    covered: true

                                });
                            }
                        });
                        
                        return {
                            ...book,
                            lines: updatedLines
                        };
                    }
                    return book;
                });
                
                setEditBookArray(updatedBookArray);
                
                setLocationsToSend([]);
                setBodilyPartsToSend([]);
                manualSetDoctors(forManualDoctors);
                setShowSaved(true); // Show the "Saved" message
                setSelectedItem(null);
                setNewOffice('');
                setTimeout(() => {
                    setShowSaved(false); // Hide the "Saved" message after 1 second
                }, 1500);
            }
                
            })
        .catch(error => {
            console.error('Error 2 /new_office:', error);
        })
    };

    const handleKeyDown = (event) => {

        if (newOffice && event.key === 'Enter') {
            handleClick();
        }
    };

    return (
        <div className="new-office">
            {errorOffice && <p style={{ color: 'red', textShadow: 'none', margin: '0px 5px 0px 5px', padding: '0px 5px 0px 5px' }}>{errorOffice}</p>}
            <input
                type="text"
                value={newOffice}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter new Office"
                style={{ height: '28px', fontSize: '1rem', width: '70%', margin: '0px 10px 0px 0px', padding: '0px 0px 0px 15px' }}


            />
            <button style={{ fontSize: '1.1rem' }} onClick={handleClick}>Add</button>
        </div>
    );
};

export default NewOffice;
