import React, { useState, useEffect } from 'react';
import './BodyPartGroupMenu.css';

const BodyPartGroupMenu = ({ title, lines, bodily_parts, offices, docName, setDoctors, setBodilyParts, setOffices, setInsurances, setOriginalDoctors, setEditBookArray, selectedDoctor, editBookArray, setSelectedDoctor, setOpenDropdown, setKey, openDropdown, manualSetDoctors, showDeleteModal, setShowDeleteModal, handleDeleteModal, deleting, handleSaveBodyParts }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [bodyPartClicked, setBodyPartClicked] = useState(false);
    const [bodyPartsToSend, setBodyPartsToSend] = useState([]);
    const [saving, setSaving] = useState(false);
    const [keyTwo, setKeyTwo] = useState(0);
    const [showAddForm, setShowAddForm] = useState(false); // State variable to track if the add form should be shown
    const [errorAgeGroupCreate, setErrorAgeGroupCreate] = useState(null);

    const updateSelectedDoctor = (updatedDoctor) => {
        setSelectedDoctor((prevDoctor) => ({
            ...prevDoctor,
            doctor: {
                ...prevDoctor.doctor,
                age_groups: prevDoctor.doctor.age_groups.map((ageGroup) => {
                    const updatedAgeGroup = updatedDoctor.doctor.age_group_array.find(
                        (updatedAgeGroup) => updatedAgeGroup.name === ageGroup.name
                    );
                    return updatedAgeGroup ? { ...ageGroup, ...updatedAgeGroup } : ageGroup;
                }),
                body_parts: prevDoctor.doctor.body_parts.map((body_part) => {
                    const updatedBodyPart = updatedBodyPart.doctor.bodily_part.find(
                        (updatedBodyPart) => updatedBodyPart.name === body_part.name
                    );
                    return updatedBodyPart ? { ...body_part, ...updatedBodyPart } : body_part;
                }),
            },
        }));
    };
    // editBookArray changes
    useEffect(() => {
        setKeyTwo(prevKey => prevKey + 1);
    }, [editBookArray]);
    const handleBodyPartClicked = (item, task) => {
        const isDuplicate = bodyPartsToSend.some(body_part => body_part.item === item);
        if (!isDuplicate) {

            if (task === 'Remove Add') {
                const updatedBodyParts = bodyPartsToSend.filter((bod) => bod !== item);
                setBodyPartsToSend(updatedBodyParts);
                if (updatedBodyParts.length === 0) {
                    setSelectedItem(null);
                }
            } else if (task === 'Remove Remove') {
                const updatedBodyParts = bodyPartsToSend.filter((bod) => bod !== item);
                setBodyPartsToSend(updatedBodyParts);
                if (updatedBodyParts.length === 0) {
                    setSelectedItem(null);
                }
            } else {
                setBodyPartsToSend(prevBodyPart => [
                    ...prevBodyPart,
                    {
                        task: task,
                        item: item
                    }
                ]);
            }
        }
    }

    const handleItemClick = (item, event, task) => {

        if (event) {
            event.stopPropagation();
        }
        // send it right to the backend to save, it was easier before and you forgot
        if (task === 'Remove') {
            handleSaveBodyParts(item.name, true);
        } else {
            handleSaveBodyParts(item.name, false);
        }
    }
    

    // useEffect(() => {
    //     console.log('BodyPartGroupMenu Props:', {
    //         title,
    //         lines,
    //         bodily_parts,
    //         offices,
    //         docName,
    //         setDoctors,
    //         setBodilyParts,
    //         setOffices,
    //         setInsurances,
    //         setOriginalDoctors,
    //         setEditBookArray,
    //         selectedDoctor,
    //         editBookArray,
    //         setSelectedDoctor,
    //         setOpenDropdown,
    //         setKey,
    //         openDropdown,
    //         manualSetDoctors,
    //         showDeleteModal,
    //         setShowDeleteModal,
    //         handleDeleteModal,
    //         deleting
    //     });

    //     console.log('BodyPartGroupMenu State:', {
    //         selectedItem,
    //         bodyPartClicked,
    //         bodyPartsToSend,
    //         saving,
    //         keyTwo,
    //         showAddForm,
    //         errorAgeGroupCreate
    //     });
    // }, [title, lines, bodily_parts, offices, docName, setDoctors, setBodilyParts, setOffices, setInsurances, setOriginalDoctors, setEditBookArray, selectedDoctor, editBookArray, setSelectedDoctor, setOpenDropdown, setKey, openDropdown, manualSetDoctors, showDeleteModal, setShowDeleteModal, handleDeleteModal, deleting, selectedItem, bodyPartClicked, bodyPartsToSend, saving, keyTwo, showAddForm, errorAgeGroupCreate]);


    return (
        <div className="dropdown-age-group" key={keyTwo}>
            <div className="dropdown-age-group-content">
                {/* Left Column Under Age Groups */}
                <div className="column-body-part-group">
                    {lines.sort((a, b) => a.name.localeCompare(b.name)).filter((line) => line.covered).map((item, i) => (
                        <div key={i} id="remove-item-age-group" className="menu-item-age-group-remove">
                            <div onClick={(event) => handleItemClick(item, event, 'Remove')} style={{ display: 'flex', flexDirection: 'row' }}><i className="far fa-check-square"></i>
                                <span style={{ color: 'black', fontWeight: '200', textShadow: 'none', marginLeft: '5px' }}>
                                    {item.name}
                                </span>
                            </div>
                        </div>
                    ))}
                    {selectedItem && (
                                <div className="details" style={{  marginTop: '10px' }}>
                                    <span style={{ textAlign: 'center', width: '95%', alignSelf: 'center', color: 'white', textShadow: 'none', opacity: '0.7', fontSize: '1.5rem', borderBottom: '1px solid white', borderRadius: '50px 0px', backgroundColor: 'rgba(218, 145, 0, 0.7)' }}>
                                        Edit
                                    </span>
                                    {/* Body Parts */}
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: '100px', padding: '0px 10px 0px 0px', margin: '10px 0px 10px 0px' }}>
                        
                                        {/* Cart */}
                                        <div style={{ width: '100%', padding: '0px 0px 0px 5px', overflowX: 'hidden', height: 'inherit' }}>
                                            {bodyPartsToSend.map((body_part, index) => (
                                                <span
                                                    key={index}
                                                    className={`cart-item ${body_part.task === 'Add' ? 'add' : 'remove'}`}
                                                    onClick={() => handleBodyPartClicked(body_part, `Remove ${body_part.task}`)}
                                                >
                                                    <span
                                                        // className="plus-or-minus"
                                                        style={{ color: body_part.task === 'Add' ? 'green' : 'red', fontSize: '1.5rem', padding: '0px 3px 0px 0px', margin: '-3px 0px 3px 0px', lineHeight: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minWidth: '0px', alignSelf: 'center', justifySelf: 'center' }}
                                                    >
                                                        {body_part.task === 'Add' ? '+' : '-'}
                                                    </span>
                                                    {body_part.name}
                                                </span>
                                            ))}
                                        </div>



                                    </div>
                                </div>
                    )}

                </div>
                {/* Right Column Under Age Groups */}
                <div className="column-body-part-group">


                    {lines
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .filter((line) => !line.covered)
                        .map((item, i) => (
                            <div
                                key={item.name}
                                id="add-item-age-group"
                                className="menu-item-body-part-group-add"
                                onClick={(event) => {
                                    handleItemClick(item, event, 'Add');
                                }} 
                            >
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <i className="far fa-square"></i>
                                    <span style={{ color: 'black', fontWeight: '200', textShadow: 'none', marginLeft: '5px' }}>
                                        {item.name}
                                    </span>
                                </div>

                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default BodyPartGroupMenu;
