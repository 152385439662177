import React, { useState, useEffect } from 'react';
import AgeGroupMenuRefactor from './helpers/AgeGroupMenuRefactor'; // Import the AgeGroupMenuRefactor component
import ApplyAgeGroupForm from './helpers/ApplyAgeGroupForm'; // Import the AgeGroupMenuRefactor component
import './AgeGroupMenu.css'; // Import the AgeGroupMenu component

const AgeGroupMenu = ({ title, lines, bodily_parts, offices, docName, setDoctors, setBodilyParts, setOffices, setInsurances, setOriginalDoctors, setEditBookArray, selectedDoctor, editBookArray, setShowSaved, setSelectedDoctor, setOpenDropdown, setKey, openDropdown, manualSetDoctors, showDeleteModal, setShowDeleteModal, handleDeleteModal, deleting, loadingEditBook, setLoadingEditBook }) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [locationClicked, setLocationClicked] = useState(false);
    const [locationsToSend, setLocationsToSend] = useState([]);
    const [bodilyPartsClicked, setBodilyPartsClicked] = useState(false);
    const [bodilyPartsToSend, setBodilyPartsToSend] = useState([]);
    const [saving, setSaving] = useState(false);
    const [keyTwo, setKeyTwo] = useState(0);
    const [showAddForm, setShowAddForm] = useState(false); // State variable to track if the add form should be shown
    const [errorAgeGroupCreate, setErrorAgeGroupCreate] = useState(null);
    const [watch, setWatch] = useState(false);
    const [loading, setLoading] = useState(false);
    const [multipleAgeGroups, setMultipleAgeGroups] = useState(false);

    const updateSelectedDoctor = (updatedDoctor) => {
        setSelectedDoctor((prevDoctor) => ({
            ...prevDoctor,
            doctor: {
                ...prevDoctor.doctor,
                age_groups: prevDoctor.doctor.age_groups.map((ageGroup) => {
                    const updatedAgeGroup = updatedDoctor.doctor.age_group_array.find(
                        (updatedAgeGroup) => updatedAgeGroup.name === ageGroup.name
                    );
                    return updatedAgeGroup ? { ...ageGroup, ...updatedAgeGroup } : ageGroup;
                }),
                locations: prevDoctor.doctor.offices.map((office) => {
                    const updatedOffice = updatedDoctor.doctor.offices.find(
                        (updatedOffice) => updatedOffice.name === office.name
                    );
                    return updatedOffice ? { ...office, ...updatedOffice } : office;
                }),
            },
        }));
    };
    useEffect(() => {
        console.log('AgeGroupMenu Props:', { title, lines, bodily_parts, offices, docName, selectedDoctor, editBookArray, deleting, loadingEditBook, setLoadingEditBook });
    }, [title, lines, bodily_parts, offices, docName, selectedDoctor, editBookArray, deleting, loadingEditBook, setLoadingEditBook]);

    // Log state
    useEffect(() => {
        console.log('AgeGroupMenu State:', { selectedItem, locationClicked, locationsToSend, bodilyPartsClicked, bodilyPartsToSend, saving, keyTwo, showAddForm, errorAgeGroupCreate, watch, loading, multipleAgeGroups });
    }, [selectedItem, locationClicked, locationsToSend, bodilyPartsClicked, bodilyPartsToSend, saving, keyTwo, showAddForm, errorAgeGroupCreate, watch, loading, multipleAgeGroups]);

    useEffect(() => {
        setKeyTwo(prevKey => prevKey + 1);
    }, [editBookArray]);
    useEffect(() => {
        let isMounted = true;
        if (isMounted && showDeleteModal) {
            setWatch(true);
        }
        if (watch && !showDeleteModal) {
            setSelectedItem(null);
            setWatch(false);
        }
        return () => {
          isMounted = false;
        };
      }, [showDeleteModal, selectedItem, setSelectedItem]);
      useEffect(() => {
        const count = selectedDoctor.doctor.age_groups.filter(group => group.covered).length;
        // console.log('count', count);
        setMultipleAgeGroups(count >= 2);
    }, [selectedDoctor.doctor.age_groups, setMultipleAgeGroups]);
    
    const handleSave = () => {
        setSaving(true);
        setLoading(true);
        const item = selectedItem.relation.body_parts;
        const locationsData = locationsToSend.map(location => ({
            task: location.task,
            item: location.item,
            doctor_name: docName,
            ageGroupId: selectedItem.relation.age_group_id,
            doctorAgeGroupRelationId: selectedItem.relation.id
        }));
        const bodilyPartsData = bodilyPartsToSend.map(bodilyPart => ({
            task: bodilyPart.task,
            item: bodilyPart.item,
            doctor_name: docName,
            ageGroupId: selectedItem.relation.age_group_id,
            doctorAgeGroupRelationId: selectedItem.relation.id
        }));
        const dataToSend = [...locationsData, ...bodilyPartsData];
        const trueBodyParts = editBookArray.find(book => book.title === "Body Parts").lines
          .filter(line => line.covered && !dataToSend.some(data => data.task.startsWith('Remove') && data.item === line.name))
          .map(line => line.name);
        const futureBodyPartsSet = new Set([...dataToSend
          .filter(data => data.task !== 'Remove Body Part') // Exclude items with task 'Remove Body Part'
          .map(data => data.item), ...trueBodyParts]);
        const futureBodyParts = [...futureBodyPartsSet];
        const trueOffices = editBookArray.find(book => book.title === "Offices").lines
          .filter(line => line.covered && !dataToSend.some(data => data.task.startsWith('Remove') && data.item === line.name))
          .map(line => line.name);

        const futureOfficesSet = new Set([...dataToSend
            .filter(data => data.task !== 'Remove Office' && !data.task.includes('Body')) // Exclude items with task 'Remove Office'
            .map(data => {
                return data.item;
            }), ...trueOffices.map(office => {
                return office;
            })]);
        const futureOffices = [...futureOfficesSet];
        const csrfToken = localStorage.getItem('csrfToken');
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        let endpoint = `${baseUrl}/doctor/update_age_group`;

        if (multipleAgeGroups) {
            endpoint = `${baseUrl}/age_groups/update_age_group_multiple_groups`;
        }
        fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify({ data: dataToSend }), // Wrap the data in an object
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            }
        })
        .then((response) => {
            if (response.ok) {
                response.json().then((data) => {
                    const oldBodyParts = selectedItem.relation.body_parts;
                    let done = false;
                    setSaving(false);
                    setEditBookArray(null);
                    const holdSelectedItem = selectedItem;
                    let updatedBookArray = null;
                    updatedBookArray = editBookArray.map((book) => {
                        if (book.title === 'Age Groups') {
                            let updatedLines = null;
                            updatedLines = book.lines.map((line) => {
                                if (line.relation) {
                                    const isBodyPartToAdd = bodilyPartsToSend.some(part => futureBodyParts.includes(part.item));
                                    const isBodyPartToKeep = null;
                                    if (isBodyPartToAdd) {
                                        return {
                                            ...line,
                                            covered: true
                                        }                                        
                                    } else {
                                        return {
                                            ...line,
                                            covered: false
                                        }
                                    }
                                } else {
                                    if (line.name === selectedItem.name) {
                                        const ageGroup = data.doctor.age_groups.find((ag) => ag.name === line.name);
                                        if (ageGroup) {
                                            return {
                                                ...line,
                                                relation: ageGroup.relation,
                                                locations: ageGroup.relation.locations,
                                                body_parts: ageGroup.relation.body_parts,
                                                covered: true
                                            };
                                        } else {
                                            return {
                                                ...line,
                                                relation: null,
                                                locations: null,
                                                body_parts: null,
                                                covered: false
                                            };
                                        }
                                    }
                                    if (line.relation) {
                                        return {
                                            ...line,
                                            relation: {
                                                ...line.relation,
                                            },
                                            locations: line.relation.locations,
                                            body_parts: line.relation.body_parts,
                                            covered: true
                                        };
                                    }
                                    return line;
                                }
                            });
                            return {
                                ...book,
                                lines: updatedLines
                            };
                        } else if (book.title === 'Body Parts') {
                            let updatedLines = null;
                            updatedLines = book.lines.map((line) => {
                                if (futureBodyParts.includes(line.name)) {
                                    return {
                                        ...line,
                                        covered: true
                                    }
                                } else {
                                    return {
                                        ...line,
                                        covered: false
                                    }
                                }
                            })
                            return {
                                ...book,
                                lines: updatedLines
                            }
                        } else if (book.title === 'Insurances') {
                            const updatedLines = book.lines.map((line) => {
                                const insurance = data.doctor.insurances.find((ag) => ag.name === line.name);
                                if (insurance) {
                                    return {
                                        ...line,
                                        covered: true,
                                        
                                    };
                                } else {
                                    return {
                                        ...line,
                                        covered: false,                                        
                                    };
                                }
                                return {
                                    ...line,
                                    covered: false,
                                    
                                };
                            });                        
                            data.doctor.insurances.forEach((insurance) => {
                                const existingLine = updatedLines.find((line) => line.name === insurance.name);
                                if (!existingLine) {
                                    updatedLines.push({
                                        name: insurance.name,
                                        id: insurance.id,
                                        covered: true
    
                                    });
                                }
                            });
                            
                            return {
                                ...book,
                                lines: updatedLines
                            };
                        } else if (book.title === 'Offices') {
                            const updatedLines = book.lines.map((line) => {
                                const office = data.doctor.offices.find((ag) => ag.name === line.name);
                                if (office) {
                                    return {
                                        ...line,
                                        covered: true,
                                        
                                    };
                                } else {
                                    return {
                                        ...line,
                                        covered: false  
                                    };
                                }
                                return {
                                    ...line,
                                    covered: false
                                };
                            });
                            data.doctor.offices.forEach((office) => {
                                const existingLine = updatedLines.find((line) => line.name === office.name);
                                if (!existingLine) {
                                        if (futureOffices.includes(existingLine.name)) {
                                            updatedLines.push({
                                                ...existingLine,
                                                covered: true            
                                            });
                                        } else {
                                            updatedLines.push({
                                                ...existingLine,
                                                covered: false            
                                            });
                                        }
                                }
                            });                            
                            return {
                                ...book,
                                lines: updatedLines
                            };
                        }
                    });
                    let updatedSelectedItem = data.doctor.age_groups.find((ag) => ag.name === selectedItem.name);
                    if (updatedSelectedItem) {
                        setSelectedItem(null); // Clear selectedItem if the group is found
                    }
                    const forManualDoctors = data.doctor.doctors_scheduler;
                    setEditBookArray(updatedBookArray);
                    updateSelectedDoctor(data);
                    setLocationsToSend([]);
                    setBodilyPartsToSend([]);
                    manualSetDoctors(forManualDoctors);
                    setShowSaved(true); // Show the "Saved" message
                    setLoading(false);
                    // Update selectedItem based on changes in bodilyPartsToSend or locationsToSend
                    updatedSelectedItem = { ...selectedItem }; // Create a copy of selectedItem to avoid mutating state directly
                    if (locationsToSend.length > 0) {
                        const newLocations = locationsToSend.map(location => location.item);
                        // Remove existing locations that are in newLocations
                        updatedSelectedItem.relation.locations = updatedSelectedItem.relation.locations.filter(
                            location => !newLocations.includes(location)
                        );
                        // Add new locations
                        updatedSelectedItem.relation.locations = [...updatedSelectedItem.relation.locations, ...newLocations];
                    }
                    if (bodilyPartsToSend.length > 0) {
                        // Step 1: Initialize the array of new body parts with the 'toBeDeleted' flag
                        let itemName = null;
                        const newBodyParts = bodilyPartsToSend.map(bodilyPart => {
                            itemName = bodilyPart.item;
                            // Check if the body part is in any age group's relations.body_parts or in bodily_parts
                            const isBodyPartInAgeGroups = data.doctor.age_groups.some(ageGroup => ageGroup.relation.body_parts.includes(itemName));
                            const isBodyPartInBodilyParts = data.doctor.bodily_parts.some(bodilyPart => bodilyPart.name === itemName);
                            return {
                                item: itemName,
                                toBeDeleted: !isBodyPartInAgeGroups && !isBodyPartInBodilyParts
                            };
                        }).filter(bodyPart => !bodyPart.toBeDeleted).map(bodyPart => bodyPart.item);
                        if (newBodyParts.length === 0) {
                            // Remove items from updatedSelectedItem.relation.body_parts
                            selectedItem.relation.body_parts = selectedItem.relation.body_parts.filter(bodyPart => bodyPart !== itemName);
                        }
                        // Add new body parts that are not already in updatedSelectedItem.relation.body_parts
                        newBodyParts.forEach(bodyPart => {
                            if (!updatedSelectedItem.relation.body_parts.includes(bodyPart)) {
                                updatedSelectedItem.relation.body_parts.push(bodyPart);
                            }
                        });
                    }
                    // Update selectedItem state
                    setSelectedItem(updatedSelectedItem);
                    // Update editBookArray lines based on changes in bodilyPartsToSend or locationsToSend
                    let updatedEditBookArray = editBookArray.map(book => {
                        let updatedLines = book.lines.map(line => {
                            if (line.name === title && title !== 'Body Parts') {
                                let updatedLine = { ...line }; // Create a copy of the line to avoid mutating state directly
                                // Update locations if locationsToSend has changes
                                if (locationsToSend.length > 0) {
                                    const newLocations = locationsToSend.map(location => location.item);
                                    updatedLine.relation.locations = Array.from(new Set([...updatedLine.relation.locations, ...newLocations]));
                                }
                                // Update body_parts if bodilyPartsToSend has changes
                                if (bodilyPartsToSend.length > 0) {
                                    const newBodyParts = bodilyPartsToSend.map(bodilyPart => bodilyPart.item);
                                    updatedLine.relation.body_parts = Array.from(new Set([...updatedLine.relation.body_parts, ...newBodyParts]));
                                }
                                return updatedLine;
                            }
                            return line;
                        });
                    
                        return { ...book, lines: updatedLines };
                    });
                    updatedEditBookArray = editBookArray.map(book => {
                        if (book.title === "Body Parts") {
                            // Update the book with new data from updatedBookArray
                            const updatedBook = updatedBookArray.find(updatedBook => updatedBook.title === "Body Parts");
                            return updatedBook || book; // Use updatedBook if found, otherwise keep the existing book
                        } else if (book.title === "Offices") {
                            // Update the book with new data from updatedBookArray
                            const updatedBook = updatedBookArray.find(updatedBook => updatedBook.title === "Offices");
                            return updatedBook || book; // Use updatedBook if found, otherwise keep the existing book
                        } else if (book.title === "Age Groups") {
                            // Find the age group with the same name as book.title
                            let lineToUpdate = null;
                            const ageGroupToUpdate = data.doctor.age_groups.find(ageGroup => {
                                let theNewBook = null;
                                theNewBook = book.lines.find((line) => {
                                    if (line.name === ageGroup.name) {
                                        line.relation.locations = ageGroup.relation.locations;
                                    }
                                });
                                // return line.name.includes(ageGroup.name);
                            });
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        
                        // git commit -m "offices added and removed in either age groups or
                        //  offices updates in both automatically. ready to test every feature
                        //  like new age group, and test multiple age groups with new
                        //  controller"

                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                        // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
                            if (ageGroupToUpdate) {
                                const updatedAgeGroup = { ...ageGroupToUpdate, ...book }; // Merge existing age group with book data
                                return updatedAgeGroup; // Return the updated age group
                            }
                        }
                        return book; // Return other books unchanged
                        });                        
                        // Update editBookArray state
                        setEditBookArray(updatedEditBookArray);
                    setTimeout(() => {
                        setShowSaved(false); // Hide the "Saved" message after 1 second
                        setOpenDropdown(true);
                    }, 1500);
                });
            }
        })
        .catch((error) => {
            console.error('Error /update_age_group', error);
            setLoading(false);
            setSaving(false);
        });
        
    };
    const handleLocationClicked = (item, task) => {
        // console.log('item', item);
        // console.log('task', task);
        // console.log('locationsToSend.some(location => location.item === item)', locationsToSend.some(location => location.item === item));
        
        const isDuplicate = locationsToSend.some(location => location.item === item);
        if (!isDuplicate) {

            if (task === 'Add Office') {
                // console.log('Add Office');
                setLocationsToSend(prevLocations => [
                    ...prevLocations,
                    {
                        task: task,
                        item: item
                    }
                ]);
            } else if (task === 'Remove Office') {
                // console.log('Remove Office');
                setLocationsToSend(prevLocations => [
                    ...prevLocations,
                    {
                        task: task,
                        item: item
                    }
                ]);
            } else if ('Remove Remove Office') {
                // console.log('Remove Remoce Office');
                const updatedLocations = locationsToSend.filter((loc) => loc !== item);
                setLocationsToSend(updatedLocations);
            } else if ('Remove Add Office') {
                // console.log('Remove Add Office');
                const updatedLocations = locationsToSend.filter((loc) => loc === item);
                setLocationsToSend(updatedLocations);
            } else {
                // console.log('Else Office');
                setLocationsToSend(prevLocations => [
                    ...prevLocations,
                    {
                        task: task,
                        item: item
                    }
                ]);
            }
        } else {
            // console.log('Else Duplicate Office');
        }
    }
    const handleBodilyPartClicked = (item, task) => {

        const isDuplicate = bodilyPartsToSend.some(body_part => body_part.item === item);
        if (!isDuplicate) {

            if (task === 'Add Body Part') {
                // console.log('Add Body Part');
                setBodilyPartsToSend(prevBodyPart => [
                    ...prevBodyPart,
                    {
                        task: task,
                        item: item
                    }
                ]);
            } else if (task === 'Remove Body Part') {
                // console.log('Remove Body Part');
                setBodilyPartsToSend(prevBodyPart => [
                    ...prevBodyPart,
                    {
                        task: task,
                        item: item
                    }
                ]);
            } else if ('Remove Remove Body Part') {
                // console.log('Remove Remove Body Part');
                const updatedBodilyParts = bodilyPartsToSend.filter((bod) => bod !== item);
                setBodilyPartsToSend(updatedBodilyParts);
            } else if ('Remove Add Body Part') {
                // console.log('Remove Add Body Part');
                const updatedBodilyParts = bodilyPartsToSend.filter((bod) => bod === item);
                setBodilyPartsToSend(updatedBodilyParts);
            } else {
                // console.log('Else Body Part');
                setBodilyPartsToSend(prevBodyPart => [
                    ...prevBodyPart,
                    {
                        task: task,
                        item: item
                    }
                ]);
            }
        } else {
            // console.log('Else Duplicate Body Part');
        }
    }
    const handleItemClick = (item, event) => {
        if (event) {
            event.stopPropagation();
        }

        if (item.relation) {
            if (selectedItem === item) {
                setSelectedItem(null);
            } else {
                setSelectedItem(item);
            }
        } else {
            // console.log('No item.relation');
        }
    }
    const handleItemClickRightSide = (item, event) => {
        event.stopPropagation();
        const isApplyFormClicked = event.target.closest('.apply-dropdown-age-group') !== null;
        if (!isApplyFormClicked) {
            if (selectedItem === item) {
                setSelectedItem(null);
            } else {
                setSelectedItem(item);
            }
        }
    }
    let minVar = 0;
    let maxVar = 0;
    
    if (loading) {
        return (
                        <div className="clock-arm-animation"></div>
        );
    } else {
        console.log('lines', lines);
        return (
            <div className="dropdown-age-group" key={keyTwo}>
                <div className="dropdown-age-group-content">
                    {/* Left Column Under Age Groups */}
                    <div className="column-age-group">
                    {lines.sort((a, b) => {
                        // First compare by minimum age
                        if (a.range.min !== b.range.min) {
                            return a.range.min - b.range.min;
                        }
                        // If minimum ages are the same, compare by maximum age
                        return a.range.max - b.range.max;
                    }).filter((line) => line.covered).map((item, i) => (
                            <div key={i} id="remove-item-age-group" className="menu-item-age-group">
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <i onClick={(event) => handleItemClick(item, event)} className="far fa-check-square"></i>
                                    <span  onClick={(event) => handleItemClick(item, event)} style={{ color: 'black', fontWeight: '200', textShadow: 'none', marginLeft: '5px' }}>
                                        {item.name}{" "}({item.range?.min}-{item.range?.max})
                                    </span>
                                </div>
                                {selectedItem && selectedItem.name === item.name && (
                                    <div className="details" style={{  marginTop: '10px' }}>
                                        <span style={{ textAlign: 'center', width: '95%', alignSelf: 'center', color: 'white', textShadow: 'none', opacity: '0.7', fontSize: '1.5rem', borderBottom: '1px solid white', borderRadius: '50px 0px', backgroundColor: 'rgba(218, 145, 0, 0.7)' }}>Edit</span>
                                        {/* Locations */}
                                        <div className="locations-body-parts-range-responsive">
                                            <div className='locations-responsive'>

                                                <p>Locations:</p>
                                                {/* Offices Covered */}
                                                {/* *************************************************************************** */}
                                                <ul className="horizontal-scroll">
            
        
                                                {selectedItem.relation.locations
                                                    .sort((a, b) => a.localeCompare(b))
                                                    .map((office, index) => (
                                                        <li key={office}
                                                            onClick={() => handleLocationClicked(office, 'Remove Office')}
                                                            id='age-group-list-remove-two'
                                                        >
                                                            {office}
                                                            <span style={{ color: 'red', marginRight: '5px', fontSize: '1.2rem' }}>
                                                                -
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
    {/* .............................................................................................................................................................. */}
                                            {/* Cart */}
                                            <div className='locations-responsive'>
                                                <div style={{ width: '100%', padding: '0px 0px 0px 5px', overflowX: 'hidden', height: 'inherit' }}>
                                                    {locationsToSend.map((location, index) => (
                                                        <span
                                                            key={index}
                                                            className={`cart-item ${location.task.startsWith('Add') ? 'add' : 'remove'}`}
                                                            onClick={() => {
                                                                // console.log('location', location);
                                                                handleLocationClicked(location, `Remove ${location.task}`);
                                                            }} 
                                                        >
                                                            <span
                                                                // className="plus-or-minus"
                                                                style={{ color: location.task.startsWith('Add') ? 'green' : 'red', fontSize: '1.2rem', padding: '0px 3px 0px 0px', margin: '-3px 0px 3px 0px', lineHeight: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minWidth: '0px', alignSelf: 'center', justifySelf: 'center' }}
                                                            >
                                                                {location.task.startsWith('Add') ? '+' : '-'}
                                                            </span>
                                                            {location.item}
                                                        </span>
                                                    ))}
                                                </div>
                                            </div>
    
    
                                            {/* Offices Not Covered */}
                                            <div className='not-covered-responsive'>
                                                <ul className="horizontal-scroll" style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-start', flexDirection: 'column', flexFlow: 'column', overflowX: 'hidden', minWidth: '180px', height: 'inherit', maxHeight: '200px', padding: '0px', margin: '0px' }}>
                                                    {offices
                                                        .filter(office => !selectedItem.relation.locations.some(location => location === office))
                                                        .sort((a, b) => a.localeCompare(b))
                                                        .map((office, index) => (
                                                            <li key={index} 
                                                                onClick={() => handleLocationClicked(office, 'Add Office')}
                                                                className='age-group-list-add'>
                                                                <span style={{ color: 'green', marginLeft: '5px', fontSize: '1.2rem' }}>
                                                                    +
                                                                </span>
                                                                {office}
                                                            </li>
                                                        ))
                                                    }
                                                    
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="locations-body-parts-range-responsive">
                                            {/* Body Parts */}
                                            <div className='locations-responsive'>
                                                <p>Body Parts:</p>
                                                {/* Body Parts Covered */}
                                                <ul className="horizontal-scroll" style={{ display: 'flex', flexDirection: 'column', flexFlow: 'column', overflowX: 'hidden', height: 'inherit', padding: '0px', margin: '0px', minWidth: '180px' }}>
                                                    {selectedItem.relation.body_parts.map((bodily_part, index) => (
                                                        <li key={index}
                                                            onClick={() => handleBodilyPartClicked(bodily_part, 'Remove Body Part')}
                                                            className='age-group-list-remove'
                                                        >
                                                            {bodily_part}
                                                            <span style={{ color: 'red', marginRight: '5px' }}>-</span>
                                                        </li>
        
                                                    ))}
                                                    <div className="scroll-container">
                                                
        
                                                    </div>
                                                </ul>
                                            </div>
                                            <div className='locations-responsive'>
                                                {/* Cart */}
                                                <div style={{ width: '100%', padding: '0px 0px 0px 5px', overflowX: 'hidden', height: 'inherit' }}>
                                                {bodilyPartsToSend.map((bodily_part, index) => (
                                                <span
                                                    key={index}
                                                    className={`cart-item ${bodily_part.task === 'Add' ? 'add' : 'remove'}`}
                                                    onClick={() => handleBodilyPartClicked(bodily_part, `Remove ${bodily_part.task}`)}
                                                >
                                                    <span
                                                    // className="plus-or-minus"
                                                    style={{ color: bodily_part.task.startsWith('Add') ? 'green' : 'red', fontSize: '1.5rem', padding: '0px 3px 0px 0px', margin: '-3px 0px 3px 0px', lineHeight: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center', minWidth: '0px', alignSelf: 'center', justifySelf: 'center' }}
                                                    >
                                                    {bodily_part.task.startsWith('Add') ? '+' : '-'}
                                                    </span>
                                                    {bodily_part.item}
                                                </span>
                                                ))}
                                                </div>
                                                </div>
                                                <div className='locations-responsive'>


                                                {/* Body Parts Not Covered */}
                                                <ul className="horizontal-scroll" style={{ display: 'flex', alignContent: 'center', justifyContent: 'flex-start', flexDirection: 'column', flexFlow: 'column', overflowX: 'hidden', minWidth: '180px', height: 'inherit', padding: '0px', margin: '0px' }}>
                                                    {bodily_parts
                                                        .filter(body_part => !selectedItem.relation.body_parts.some(part => part === body_part.name))
                                                        .sort((a, b) => a.name.localeCompare(b.name))
                                                        .map((body_part, index) => (
                                                            <li key={index} onClick={() => handleBodilyPartClicked(body_part.name, 'Add Body Part')} className='age-group-list-add'>
                                                            <span style={{ color: 'green', marginLeft: '5px' }}>+</span>
                                                                
                                                                {body_part.name}
                                                            </li>
                                                        ))}
                                                    
                                                </ul>
                                                </div>

                                        </div>
                                        <div className='cart-responsive'>
                                            {/* Range */}
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: '60px', padding: '0px', margin: '10px 0px 10px 0px', minWidth: '100%' }}>
                                                <p>Range:</p>
                                                <p className="dropdown-item-age-group">Min Age: {selectedItem.range.min}</p>
                                                <p className="dropdown-item-age-group">Max Age: {selectedItem.range.max}</p>
                                            </div>
                                            {/* Delete AgeGroup/ Save AgeGroup */}
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: '60px', padding: '0px', margin: '10px 0px 10px 0px', minWidth: '100%' }}>
                                                <button style={{ fontSize: '1.1rem' }} onClick={() => handleDeleteModal(selectedItem)} disabled={deleting}>{deleting ? 'Deleting...' : 'Delete'}</button>
                                                {locationsToSend.length > 0 || bodilyPartsToSend.length > 0 ? (
                                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', height: '60px', padding: '0px', margin: '10px 0px 10px 40px' }}>
                                                        <button style={{ fontSize: '1.1rem' }} onClick={handleSave} disabled={saving}>{saving ? 'Saving...' : 'Save'}</button>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {/* Right Column Under Age Groups */}
                    <div className="column-age-group">
                        {/* Looks like just Add Age Group */}
                        {title === "Age Groups" && (
                            <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'flex-start', width: '100%' }}>
                                <div
                                    id="add-item-age-group"
                                    className="menu-item-age-group"
                                    style={{ display: 'flex', flexDirection: 'row' }}
                                    onClick={() => setShowAddForm(!showAddForm)}
                                >
                                    <i style={{ margin: "10px 10px 10px 10px" }} className={showAddForm ? "far fa-edit" : "far fa-square"}></i>
                                    Add Age Group {errorAgeGroupCreate && <p style={{ padding: '0px 25px 0px 25px', margin: '0px 0px 0px 0px', color: 'red' }}>
                                        {errorAgeGroupCreate}</p>}
                                </div>
                            </div>
                        )}
    
                        {showAddForm && (
                            <AgeGroupMenuRefactor editBookArray={editBookArray} offices={offices} bodily_parts={bodily_parts} selectedItem={selectedItem} setEditBookArray={setEditBookArray} docName={docName} manualSetDoctors={manualSetDoctors} updateSelectedDoctor={updateSelectedDoctor} errorAgeGroupCreate={errorAgeGroupCreate} setErrorAgeGroupCreate={setErrorAgeGroupCreate} setShowSaved={setShowSaved} setShowAddForm={setShowAddForm} setSavingObject={setSaving} setLoadingObject={setLoading} loadingEditBook={loadingEditBook} setLoadingEditBook={setLoadingEditBook} />
                        )}
                        {/* Other Age Groups */}
                        {lines
                            .sort((a, b) => {
                                // First compare by minimum age
                                if (a.range.min !== b.range.min) {
                                    return a.range.min - b.range.min;
                                }
                                // If minimum ages are the same, compare by maximum age
                                return a.range.max - b.range.max;
                            })
                            .filter((line) => !line.covered)
                            .map((item, i) => (
                                <div
                                    key={item.name}
                                    id="add-item-age-group"
                                    className="menu-item-age-group"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        minVar = item.range?.min;
                                        maxVar = item.range?.max;
                                        handleItemClickRightSide(item, event);
                                    }} 
                                >
    {/* --------------------------------------------------------------------- Currently Here ------------------------------------------------------------- */}
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <i className="far fa-square"></i>
                                        {item.name} ({item.range?.min}-{item.range?.max})
                                    </div>
    {/* --------------------------------------------------------------------- Currently Here ------------------------------------------------------------- */}
                                    {selectedItem && selectedItem.name === item.name && (
                                        <ApplyAgeGroupForm
                                            i={i}
                                            item={item}
                                            selectedItem={selectedItem}
                                            setErrorAgeGroupCreate={setErrorAgeGroupCreate}
                                            docName={docName}
                                            editBookArray={editBookArray}
                                            setShowSaved={setShowSaved}
                                            setEditBookArray={setEditBookArray}
                                            updateSelectedDoctor={updateSelectedDoctor}
                                            manualSetDoctors={manualSetDoctors}
                                            setSelectedItem={setSelectedItem}
                                            bodily_parts={bodily_parts}
                                            handleBodilyPartClicked={handleBodilyPartClicked}
                                            handleLocationClicked={handleLocationClicked}
                                            offices={offices}
                                            title={title}
                                            lines={lines}
                                            loading={loading}
                                            setLoadingObject={setLoading}
                                            setSavingObject={setSaving}
                                            loadingEditBook={loadingEditBook}
                                            setLoadingEditBook={setLoadingEditBook}
                                        />
                                    )}
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        );
    }
};

export default AgeGroupMenu;
