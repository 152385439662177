import React, { useState, useEffect } from 'react';

const NumberPicker = ({ handleTextChange, onMouseEnter, onMouseLeave, isConfirmed, handleConfirm, focusInput }) => {
  const [inputText, setInputText] = useState('');

  useEffect(() => {
    if (!isConfirmed) {
      setInputText('');
    }
  }, [isConfirmed]);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
    handleTextChange(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !isConfirmed) {
      handleConfirm();
    }
  };

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column'
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <input
        type="text"
        id="number_input"
        ref={focusInput}
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type age and hit enter"
        style={{
          padding: '5px',
          fontSize: '14px',
          border: '1px solid #ddd',
          backgroundColor: '#fff',
          borderRadius: '4px',
        }}
        disabled={isConfirmed}
        autoFocus={true}
      />
      {!isConfirmed && inputText !== '' && (
        <button
          onClick={handleConfirm}
          style={{
            marginTop: '10px',
            padding: '5px 10px',
            width: '60%',
            fontSize: '14px',
            background: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
          }}
        >
          Confirm Age
        </button>
      )}
    </div>
  );
};

export default NumberPicker;
