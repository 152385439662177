import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import NumberPicker from './helpers/NumberPicker'; // Import the NumberPicker component
import InsurancePicker from './helpers/InsurancePicker';
import BodilyPartPicker from './helpers/BodilyPartPicker';
import OfficePicker from './helpers/OfficePicker';
import DoctorPicker from './helpers/DoctorPicker';
import Edit from '../../components/navHelpers/Edit';

const SchedulerAppLayout = ({ user, doctors, setDoctors, insurances, setInsurances, resetDoctors, bodily_parts, setBodilyParts, offices, setOriginalDoctors, setOffices, manualSetDoctors }) => {
    const [textInput, setTextInput] = useState('');
    const [isConfirmed, setIsConfirmed] = useState(false);
    const [chosenInsurance, setChosenInsurance] = useState('All');
    const [isConfirmedInsurance, setIsConfirmedInsurance] = useState(false);
    const [chosenBodilyPart, setChosenBodilyPart] = useState(null);
    const [isConfirmedBodilyPart, setIsConfirmedBodilyPart] = useState(false);
    const [chosenOffice, setChosenOffice] = useState(null);
    const [isConfirmedOffice, setIsConfirmedOffice] = useState(false);
    const [isConfirmedReset, setIsConfirmedReset] = useState(false);
    const [previousDoctors, setPreviousDoctors] = useState(doctors);
    const numberPickerRef = useRef(null);
    const insuranceInputRef = useRef(null);
    const [loadingDoctors, setIsLoadingDoctors] = useState(false);
    const location = useLocation();

    const handleNumberPickerEnter = () => {
        document.body.classList.add('no-scroll');
      };
    const handleNumberPickerLeave = () => {
        document.body.classList.remove('no-scroll');
    };
    const handleInsurancePickerEnter = () => {
        document.body.classList.add('no-scroll');
    };
    const handleInsurancePickerLeave = () => {
        document.body.classList.remove('no-scroll');
    };
    const handleBodilyPartPickerEnter = () => {
        document.body.classList.add('no-scroll');
    };
    const handleBodilyPartPickerLeave = () => {
        document.body.classList.remove('no-scroll');
    };
    const resetAllVariables = () => {
        setTextInput('');
        setIsConfirmed(false);
        setChosenInsurance("All");
        setIsConfirmedInsurance(false);
        setChosenBodilyPart(null);
        setIsConfirmedBodilyPart(false);
        setChosenOffice(null);
        setIsConfirmedOffice(false);
        setIsConfirmedReset(true);
        resetDoctors();
        setTimeout(() => {
            if (numberPickerRef.current) {
                numberPickerRef.current.focus();
            }
        }, 100);
            
    };
    const handleTextPick = (text) => {
        if (textInput !== text) {
            setIsConfirmed(false);
        }
        setTextInput(text);
    };
    const handleInsurancePick = (ins) => {
        setChosenInsurance(ins);
        if (chosenInsurance !== ins) {
            setIsConfirmedInsurance(false);
        }
        setChosenInsurance(ins);
    };
    const handleBodilyPartPick = (part) => {
        if (chosenBodilyPart !== part) {
            setIsConfirmedBodilyPart(false);
        }
        setChosenBodilyPart(part);
    };
    const handleOfficePick = (office) => {
        if (chosenOffice !== office) {
            setIsConfirmedOffice(false);
        }
        setChosenOffice(office);
    };

    const handleSetIsConfirmed = () => {
        const csrfToken = localStorage.getItem('csrfToken');
        const baseUrl = process.env.REACT_APP_API_BASE_URL;

        setIsLoadingDoctors(true);
        fetch(`${baseUrl}/filter_age`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': csrfToken
            },
            body: JSON.stringify({ age: textInput, insurance: chosenInsurance, doctors: doctors }),
        }).then((res) => {
            if (res.ok) {
                res.json().then((response) => {
                    // console.log('response /filter_age', response);
                    setDoctors(response.doctors);
                    setIsLoadingDoctors(false);
                });
            } else {
                res.json().then((errors) => {
                    console.error('Error 1 /filter_age', errors);
                    setIsLoadingDoctors(false);
                });
            }
        }).catch((errors) => {
            console.log('Error 2 /filter_age', errors);
            setIsLoadingDoctors(false);
        });
    };

    const handleSetIsConfirmedInsurance = () => {
        setIsLoadingDoctors(true);
        const csrfToken = localStorage.getItem('csrfToken');
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/filter_insurance`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': csrfToken
            },
            body: JSON.stringify({ insurance: chosenInsurance, age: textInput, doctors: doctors }),
        }).then((res) => {
            if (res.ok) {
                res.json().then((response) => {
                    // console.log('response /filter_insurance', response);

                    setPreviousDoctors(doctors);
                    setDoctors(response.doctors);
                    setIsLoadingDoctors(false);
                });
            } else {
                res.json().then((errors) => {
                    console.error('Error 1 /filter_insurance', errors);
                });
            }
        }).catch((errors) => {
            console.log('Error 2 /filter_insurance', errors);
        });
    };

    const handleSetIsConfirmedBodilyPart = () => {
        setIsLoadingDoctors(true);
        const csrfToken = localStorage.getItem('csrfToken');
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/filter_bodily_part`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': csrfToken
            },
            body: JSON.stringify({ bodily_part: chosenBodilyPart, insurance: chosenInsurance, age: textInput, doctors: doctors }),
        }).then((res) => {
            if (res.ok) {
                res.json().then((response) => {
                    // console.log('response /filter_bodily_part', response);

                    setDoctors(response.doctors);
                    setIsLoadingDoctors(false);
                });
            } else {
                res.json().then((errors) => {
                    console.error('Error 1 /filter_bodily_part', errors);
                    setIsLoadingDoctors(false);
                });
            }
        }).catch((errors) => {
            console.log('Error 2 /filter_bodily_part', errors);
            setIsLoadingDoctors(false);
        });
    };
    const handleSetIsConfirmedOffice = () => {
        setIsLoadingDoctors(true);
        const csrfToken = localStorage.getItem('csrfToken');
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/select_office`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'X-CSRF-Token': csrfToken
            },
            body: JSON.stringify({ office: chosenOffice, doctors: doctors }),
        }).then((res) => {
            if (res.ok) {
                res.json().then((response) => {
                    // console.log('response /select_office', response);
                    setPreviousDoctors(doctors);
                    setDoctors(response.doctors);
                    setIsLoadingDoctors(false);
                });
            } else {
                res.json().then((errors) => {
                    console.log('Error 1 /select_office', errors);
                });
            }
        }).catch((errors) => {
            console.log('Error 2 /select_office', errors);
        });
    };
    const handleConfirm = () => {
        if (textInput !== null) {
            setIsConfirmed(true);
            handleSetIsConfirmed();
        }
    };
    const handleConfirmInsurance = () => {
        if (chosenInsurance !== null) {
            setIsConfirmedInsurance(true);
            handleSetIsConfirmedInsurance();
        }
    };
    const handleConfirmBodilyPart = () => {
        if (chosenBodilyPart !== null) {
            setIsConfirmedBodilyPart(true);
            handleSetIsConfirmedBodilyPart();
        }
    };
    const handleConfirmOffice = () => {
        if (chosenOffice !== null) {
            setIsConfirmedOffice(true);
            handleSetIsConfirmedOffice();
        }
    };
    const handleTextChange = (text) => {
        setTextInput(text);
        setIsConfirmed(false);
    };
    const handleInsuranceTextChange = (text) => {
        setChosenInsurance(text);
        setIsConfirmedInsurance(false);
    };
    const handleBodilyPartTextChange = (text) => {
        setChosenBodilyPart(text);
        setIsConfirmedBodilyPart(false);
    };
    const savePreviousDoctors = () => {
        setPreviousDoctors([...doctors]);
      };
    const revertToPreviousDoctors = () => {
        setDoctors([...previousDoctors]);
        setIsConfirmedOffice(false); // Reset the office confirmation flag
    };
    return (
        <div style={{ height: '1000px' }}>
            {user !== null ? (
                <div>
                    {location.pathname === '/edit' ? <Edit user={user} doctors={doctors} setDoctors={setDoctors} insurances={insurances} setInsurances={setInsurances} bodily_parts={bodily_parts} offices={offices} setBodilyParts={setBodilyParts} resetDoctors={resetDoctors} setOriginalDoctors={setOriginalDoctors} setOffices={setOffices} manualSetDoctors={manualSetDoctors} /> : (
                        <div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <button id="reset_button" style={{ marginLeft: '67px', marginTop: '10px' }} onClick={resetAllVariables}>Reset</button>
                            </div>
                            <div className='scheduler-app-container'>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <div className='scheduler-age-container'>
                                        <NumberPicker handleTextChange={handleTextChange} isConfirmed={isConfirmed} textInput={textInput} handleConfirm={handleConfirm} isConfirmedReset={isConfirmedReset} focusInput={numberPickerRef} />
                                        {isConfirmed && (
                                            <div>
                                                Age: {textInput}
                                            </div>
                                        )}
                                    </div>
                                    {isConfirmed ? (
                                        <>
                                            <div className='scheduler-bodily-part-container'>
                                                <BodilyPartPicker handleBodilyPartTextChange={handleBodilyPartTextChange} handleClickBodilyPart={handleBodilyPartPick} bodily_parts={bodily_parts} onMouseEnter={handleBodilyPartPickerEnter} onMouseLeave={handleBodilyPartPickerLeave} isConfirmedBodilyPart={isConfirmedBodilyPart} handleConfirmBodilyPart={handleConfirmBodilyPart} isConfirmed={isConfirmed} loadingDoctors={loadingDoctors} />
                                                {isConfirmedBodilyPart && (
                                                    <div>
                                                        {chosenBodilyPart}
                                                    </div>
                                                )}                     
                                            </div>
                                        </>
                                    ) : (
                                        <div className='scheduler-bodily-part-container'>
                                            <div style={{ opacity: '50%' }}>
                                                <BodilyPartPicker disabled={!isConfirmed} handleClickBodilyPart={handleBodilyPartPick} bodily_parts={bodily_parts} onMouseEnter={handleBodilyPartPickerEnter} onMouseLeave={handleBodilyPartPickerLeave} isConfirmedBodilyPart={isConfirmedBodilyPart} />
                                            </div>
                                            <p>Please choose age</p>
                                        </div>
                                    )} 
                                    {isConfirmedBodilyPart ? (
                                        <>
                                            <div className='scheduler-insurance-container'>
                                                <InsurancePicker handleInsuranceTextChange={handleInsuranceTextChange} handleClickInsurance={handleInsurancePick} insurances={insurances} onMouseEnter={handleInsurancePickerEnter} onMouseLeave={handleInsurancePickerLeave} isConfirmedInsurance={isConfirmedInsurance} isConfirmedBodilyPart={isConfirmedBodilyPart} handleConfirmInsurance={handleConfirmInsurance} loadingDoctors={loadingDoctors} revertToPreviousDoctors={revertToPreviousDoctors} insuranceInputRef={insuranceInputRef} />
                                                {isConfirmedInsurance && (
                                                    <div>
                                                        {chosenInsurance}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <div className='scheduler-insurance-container'>
                                            <div style={{ opacity: '50%' }}>
                                                <InsurancePicker disabled={!isConfirmedBodilyPart} handleClickInsurance={handleInsurancePick} insurances={insurances} onMouseEnter={handleInsurancePickerEnter} onMouseLeave={handleInsurancePickerLeave} isConfirmedInsurance={isConfirmedInsurance} isConfirmed={isConfirmed}/>
                                            </div>
                                        </div>
                                    )}      
                                </div>
                                {isConfirmedInsurance ? (
                                        <>
                                            <div className='scheduler-office-container'>
                                                <OfficePicker disabled={false} handleClickOffice={handleOfficePick} doctors={doctors} isConfirmedOffice={isConfirmedOffice} isConfirmedInsurance={isConfirmedInsurance} handleConfirmOffice={handleConfirmOffice} chosenOffice={chosenOffice} revertToPreviousDoctors={revertToPreviousDoctors} insuranceInputRef={insuranceInputRef} />
                                            </div>
                                        </>
                                    ) : (
                                        <div className='scheduler-office-container'>
                                            <div style={{ opacity: '50%' }}>
                                                <OfficePicker disabled={true} handleClickOffice={handleOfficePick} doctors={doctors} isConfirmedOffice={isConfirmedOffice} isConfirmedInsurance={isConfirmedInsurance} handleConfirmOffice={handleConfirmOffice} chosenOffice={chosenOffice} />
                                            </div>
                                        </div>
                                    )}      
                            </div>
                            <div className='scheduler-doctor-container'>
                                <DoctorPicker doctors={doctors} loadingDoctors={loadingDoctors} isConfirmed={isConfirmed} />
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    Please sign in on the home tab to access this content.
                </div>
            )}
        </div>
    );
};

export default SchedulerAppLayout;
