import React, { useState, useEffect, Suspense } from 'react';
import { Link } from 'react-router-dom';
import SignInBox from '../components/SignInBox';
import '../components/SignInBox.css'
const Home = ({ isUserSignedIn, handleLogout, user, setIsUserSignedIn, setUser, doctors, setDoctors, insurances, setInsurances, bodily_parts, setBodilyParts, originalDoctors, setOriginalDoctors }) => {
    return (
        <main className='main-content'>
            <div className='home-expertise'>
                <SignInBox
                    isUserSignedIn={isUserSignedIn}
                    handleLogout={handleLogout}
                    user={user}
                    setIsUserSignedIn={setIsUserSignedIn}
                    setUser={setUser}
                    doctors={doctors}
                    setDoctors={setDoctors}
                    insurances={insurances}
                    setInsurances={setInsurances}
                    bodily_parts={bodily_parts}
                    setBodilyParts={setBodilyParts}
                    originalDoctors={originalDoctors}
                    setOriginalDoctors={setOriginalDoctors}
                />
            
                
            </div>
        </main>
    );
};
export default Home;
