import React, { useState, useRef, useEffect } from 'react';

const OfficePicker = ({ doctors, handleClickOffice, isConfirmedOffice, chosenOffice, handleConfirmOffice, isConfirmedInsurance, isConfirmed, disabled, revertToPreviousDoctors, loadingDoctors }) => {
  const itemHeight = 40;
  const uniqueOfficeNames = Array.from(new Set(doctors.flatMap(doctor => doctor.offices.map(office => office))));
  const [selectedOffice, setSelectedOffice] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    if (isConfirmedInsurance) {
      const initialHighlightedOffice = uniqueOfficeNames[selectedOffice];
      handleClickOffice(initialHighlightedOffice);
    }
  }, [selectedOffice, handleClickOffice, isConfirmedInsurance, uniqueOfficeNames]);

  const handleOfficeClick = (officeName) => {
    if (!isConfirmedOffice && !disabled) {
      const selectedIndex = uniqueOfficeNames.findIndex(office => office === officeName);
      setSelectedOffice(selectedIndex);
      handleClickOffice(officeName);
    }
  };

  const handleContainerKeyDown = (event) => {
    if (
      event.keyCode === 13 &&
      !isConfirmedOffice &&
      !event.defaultPrevented
    ) {
      if (chosenOffice === null) {
        if (uniqueOfficeNames.length > 0) {
          const firstOffice = uniqueOfficeNames[selectedOffice];
          handleOfficeClick(firstOffice);
          handleConfirmOffice();
          event.preventDefault();
        }
      } else {
        handleConfirmOffice();
        event.preventDefault();
      }
    } else if (
      (event.keyCode === 38 || event.keyCode === 40) &&
      isConfirmedInsurance
    ) {
      const direction = event.keyCode === 38 ? -1 : 1;
      const newIndex = (selectedOffice + direction + uniqueOfficeNames.length) % uniqueOfficeNames.length;
      setSelectedOffice(newIndex);
      event.preventDefault();
    } else if (event.keyCode === 8) {
      // Handle Backspace key press when no office is selected
      if (revertToPreviousDoctors) {
        revertToPreviousDoctors();
      }
      event.preventDefault();
    }
  };

  const handleSelectClick = () => {
    if (!isConfirmedOffice && !isConfirmed) {
      handleConfirmOffice();
      containerRef.current.focus(); // Keep focus on the office list after clicking "Select"
    }
  };

  useEffect(() => {
    if (isConfirmedInsurance) {
      containerRef.current.focus();
    }
  }, [isConfirmedInsurance]);
  
  if (!isConfirmedInsurance) {
    return null; // Render nothing if isConfirmedInsurance is false
  }

  return (
    <div
      style={{
        width: '200px',
        overflow: 'hidden',
        cursor: 'default',
        position: 'relative',
        outline: 'none'
      }}
      onKeyDown={handleContainerKeyDown}
      tabIndex="0"
      ref={containerRef}
      // autoFocus={isConfirmedInsurance}
      disabled={isConfirmedOffice || !isConfirmedInsurance}
    >
      <div
        style={{
          height: '100%',
          cursor: 'default',
          overflowY: 'scroll',
        }}
      >
        {uniqueOfficeNames.sort().map((officeName, index) => (
          <div
            key={index}
            onClick={() => handleOfficeClick(officeName)}
            style={{
              display: 'flex',
              cursor: 'default',
              alignItems: 'center',
              justifyContent: 'center',
              border: '1px solid #ddd',
              background: index === selectedOffice && isConfirmedInsurance ? '#f0f0f0' : 'transparent',
              fontSize: '0.8rem',
              fontWeight: '700'
            }}
          >
            {officeName}
          </div>
        ))}
      </div>
      {chosenOffice && !isConfirmedOffice && (
        <button
          onClick={handleSelectClick}
          style={{
            marginTop: '10px',
            padding: '5px 10px',
            width: '60%',
            fontSize: '14px',
            background: 'rgba(240, 93, 31, 0.85)',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            alignSelf: 'center',
          }}
        >
          Select
        </button>
      )}
      {uniqueOfficeNames.length === 1 &&  (
        <div
          onClick={handleSelectClick}
          style={{
            marginTop: '2px',
            padding: '3px 3px 3px 3px',
            fontSize: '16px',
            display: 'flex',
            color: 'black',
            border: 'none',
            borderRadius: '4px',
          }}
        >
         Press backspace for list
        </div>
      )}
    </div>
  );
};

export default OfficePicker;
