import react, { Component } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import 'react-dropdown/style.css'
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import './SignInBox.css';
class SignInBox extends Component {
	constructor(props){  
	    super(props);  
	    this.state = {  
	    		errorUsername: "",
	    		errorUsernameShow: false,
	    		errorPassword: "",
	    		errorPasswordShow: false,
	    		errorMessage: "",
	    		errorMessageShow: false,
	    		errorMain: "",
	    		errorMainShow: false,

	    		lastTyped: '',
	    		showAllErrors: false
		}
    } 
	onSubmit(values) {
        const { setIsUserSignedIn, setUser, user, doctors, setDoctors, insurances, setInsurances, bodily_parts, setBodilyParts, originalDoctors, setOriginalDoctors } = this.props;
		const csrfToken = localStorage.getItem('csrfToken');
		console.log('csrfToken', csrfToken);
		const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/login`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
			  'X-CSRF-Token': csrfToken
            },
			credentials: 'include', // This is key to include cookies for cross-origin
            body: JSON.stringify(values),
          }).then((res) => {
            if (res.ok) {
              res.json().then((response) => {
				console.log('response /login'. response);
				setIsUserSignedIn(true);
				setUser(response.user);
				setDoctors(response.doctors);
				setOriginalDoctors(response.doctors);
				setInsurances(response.insurances);
				setBodilyParts(response.bodily_parts);
              });
            } else {
				res.json().then((errors) => {
					const errorMessage = errors.error || 'Login failed. Please try again.';
					this.setState({ errorMessage: errorMessage, errorMessageShow: true });
				});
            }
          });
	}
	handleSubmitFunction(handleSubmit) {
        
	}
	renderErrorPassword(error) {
		if (error && this.state.showAllErrors) {
			return (
				<div id="error-div">
					<h6 style={{ display: 'flex', color: 'red', fontSize: '0.9rem', padding: '0rem', margin: '0rem' }}>
						{/* {error} */}
					</h6>
				</div>
			)
		}
	}
	renderErrorMain(error) {
		if (error && this.state.showAllErrors) {
			return (
				<div id="error-div">
					<h6 style={{ display: 'flex', color: 'red', fontSize: '0.9rem', padding: '0rem', margin: '0rem' }}>
						{/* {error} */}
					</h6>
				</div>
			)
		}
	}
	checkDoneOnForm(typing) {
		if (typing === 'location') {
			if (this.state.emailDone) {

			}
		}
	}
	inputChangeHandler(k) {
        // Reset the error state when input changes
        if (k === 'username') {
            this.setState({ errorUsername: '', errorUsernameShow: false });
        } else if (k === 'password') {
            this.setState({ errorPassword: '', errorPasswordShow: false });
        }
    }
	validateField(field, value) {
		if (field === 'fName') {
			const passed = /^[a-z]+$/i.test(field);
		}
	}

	renderErrorUsername(error) {
		if (error && this.state.showAllErrors) {
			return (
				<div id="error-div">
					<h6 style={{ display: 'flex', color: 'red', fontSize: '0.9rem', padding: '0rem', margin: '0rem' }}>
						{/* {error} */}
					</h6>
				</div>
			)
		}
	}
	renderSendButton(values, errors, form) {
		return (
			<div>
				{this.state.errorMessageShow && (
					<div style={{
							display: 'flex',
							flexFlow: 'wrap',
							color: 'red',
							margin: this.state.errorMessage.length < 40 ? '0px 0px -10px 30px' : '0px 10px 2px 30px', 
							maxWidth: '330px',
							maxHeight: '33px',
							fontSize: '16px'
						}}
					>
						{this.state.errorMessage}
					</div>
				)}
				<button id="chat-box-button-blue" onClick={(e) => {
					e.preventDefault();
					if (Object.keys(errors).length === 0 && values.username && values.password) {
						this.onSubmit(values);
						form.reset();
					} else {
						this.setState({ showAllErrors: true });
					}
				}} type="submit">
					Send
				</button>
			</div>
		);
	}
	renderBorderUsername(errors) {
		if (!errors.username || !this.state.showAllErrors) {
			return "field-id";
		} else {
			return "field-id-red";
		}
	}
	renderBorderPassword(errors) {
		if (!errors.password || !this.state.showAllErrors) {
			return "field-id";
		} else {
			return "field-id-red";
		}
	}
	render() {
        const { isUserSignedIn, handleLogout, user } = this.props;
        if (user && isUserSignedIn) {
            return (
              <div id="chatbox-div-signed-in">
                <p>{user.username}</p>
                <button onClick={handleLogout}>Logout</button>
              </div>
            );
        }

		return (
			<Form 
				validate={values => {

					const errors = {};
					let newObject = [];

					if (!values.username) {
						errors.username = "Username is empty";
					} else if (values.username.length > 20) {
						errors.username = "Username is too long";
					}

					if (!values.password) {
						errors.password = "Password is empty";
					} else if (values.password.length > 20) {
                        errors.password = "Password is too long";
                    }


					return errors;
				}}
				onSubmit={this.onSubmit}
			    render={({ props, handleSubmit, values, errors, form }) => (
			        <form style={{ display: 'flex' }} onSubmit={handleSubmit} >
						<div id="chatbox-div">
							<div id="chat-top-component">
								<div id="top-info-div">
									<h2>Sign In</h2>
								</div>
							</div>
							<div id="chat-middle-component">
								<div id="middle-form-top-div">
							      	<div id="chat-form-top-div">
							      		<div id="chat-form-inner-div">
									       	<div id="chat-form-lines">
                                                <Field
                                                    key="usernameKey"
                                                    id={this.renderBorderUsername(errors)}
                                                    style={{ borderRadius: '3px' }}
                                                    name="username"
                                                    component="input" 
                                                    placeholder="Username"
                                                />
											</div>
									        <div id="chat-form-lines">
									          	<Field
													key="passwordKey"
									          		id={this.renderBorderPassword(errors)}
									          		style={{ borderRadius: '3px' }}
									          		name="password"
									          		component="input"
									          		placeholder="Password"
                                                    type="password"
									          	/>
									        </div>
									        <div style={{ marginBottom: '0.3rem' }}>
													{this.renderErrorUsername(errors.username)}
													{this.renderErrorPassword(errors.password)}
											</div>
									    </div>
										{this.renderErrorMain(this.state.errorMain)}
							      	</div>
								</div>
							</div>
							<div id="chat-bottom-component">
									{
										this.renderSendButton(values, errors, form)
									}
							</div>
						</div>
			        </form>
			    )}
			  />
		);
	}
}
export default SignInBox;

