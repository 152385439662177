import React, { useState, useEffect, useRef } from 'react';

const InsurancePicker = ({
  handleInsuranceTextChange,
  handleClickInsurance,
  insurances,
  onMouseEnter,
  onMouseLeave,
  isConfirmedInsurance,
  handleConfirmInsurance,
  isConfirmedBodilyPart,
  loadingDoctors
}) => {
  const [selectedInsurance, setSelectedInsurance] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const inputRef = useRef(null);
  const listRef = useRef(null);

  useEffect(() => {
    if (!isConfirmedInsurance) {
      setSelectedInsurance('');
    }
  }, [isConfirmedInsurance]);

  const handleInsuranceInputChange = (event) => {
    const inputValue = event.target.value;
    setSelectedInsurance(inputValue);
    setSelectedIndex(0);
    handleInsuranceTextChange(inputValue);
  
    const filteredInsurances = insurances.filter((insurance) =>
      insurance.name.toLowerCase().startsWith(inputValue.toLowerCase().trim())
    );
  
    if (filteredInsurances.length > 0) {
      if (inputValue.length === 0) {
        handleInsuranceTextChange("All");
      } else {
        handleInsuranceTextChange(filteredInsurances[0].name);
      }
    }
  };
  
  
  const handleInsuranceSelection = (ins, index) => {
    if (!isConfirmedInsurance) {
      setSelectedInsurance(ins);
      setSelectedIndex(index);
      handleClickInsurance(ins);
    }
  };
  const filteredInsurances = insurances.filter((insurance) =>
    insurance.name.toLowerCase().startsWith(selectedInsurance.toLowerCase().trim())
  );

  const handleKeyDown = (event) => {
    if (event.key === 'ArrowDown' && selectedIndex < filteredInsurances.length - 1) {
      event.preventDefault();
      setSelectedIndex(selectedIndex + 1);
      handleInsuranceTextChange(filteredInsurances[selectedIndex + 1].name);
    } else if (event.key === 'ArrowUp' && selectedIndex > 0) {
      event.preventDefault();
      setSelectedIndex(selectedIndex - 1);
      handleInsuranceTextChange(filteredInsurances[selectedIndex - 1].name);
    } else if (event.key === 'Enter') {
      event.preventDefault();
      if (selectedIndex >= 0) {
        handleConfirmInsurance();
      } else if (filteredInsurances.length > 0) {
        handleInsuranceSelection(filteredInsurances[0].name, 0);
        handleConfirmInsurance();
      }
    }
  };

  useEffect(() => {
    if (listRef.current && selectedIndex !== -1) {
      const selectedElement = listRef.current.children[selectedIndex];
      if (selectedElement) {
        const parentRect = listRef.current.getBoundingClientRect();
        const selectedRect = selectedElement.getBoundingClientRect();
        if (selectedRect.bottom > parentRect.bottom) {
          listRef.current.scrollTop += selectedRect.bottom - parentRect.bottom;
        } else if (selectedRect.top < parentRect.top) {
          listRef.current.scrollTop -= parentRect.top - selectedRect.top;
        }
      }
    }
  }, [selectedIndex]);

  useEffect(() => {
    if (listRef.current) {
      // Find the width of the longest child list item
      let maxWidth = 0;
      Array.from(listRef.current.children).forEach((child) => {
        const childWidth = child.getBoundingClientRect().width;
        maxWidth = Math.max(maxWidth, childWidth);
      });

      // Set the component's width to the width of the longest list item
      listRef.current.style.width = `${maxWidth}px`;
    }
  }, [selectedInsurance]);

  return (
    <div
      style={{
        border: '1px solid #ccc',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column'
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <input
        ref={inputRef}
        type="text"
        value={selectedInsurance}
        onChange={handleInsuranceInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Insurance(enter for all)"
        style={{
          padding: '5px',
          fontSize: '14px',
          backgroundColor: '#fff',
          border: '1px solid #ddd',
          borderRadius: '4px',
        }}
        disabled={isConfirmedInsurance || !isConfirmedBodilyPart}
        autoFocus={isConfirmedBodilyPart}
      />
      {selectedInsurance !== '' && !isConfirmedInsurance && (
        <div ref={listRef} style={{ marginTop: '10px', maxHeight: '100px', overflowY: 'auto' }}>
          {filteredInsurances.map((insurance, index) => (
            <div
              key={index}
              onClick={() => handleInsuranceSelection(insurance.name, index)}
              style={{
                padding: '5px',
                cursor: 'pointer',
                backgroundColor: selectedIndex === index ? '#f0f0f0' : 'transparent',
                minWidth: '200px',
                width: '100%', // Set the width to 100% to ensure it takes the full container width
              }}
            >
              <div style={{ fontSize: '1rem' }}>
                {insurance.name}
              </div>
            </div>
          ))}
        </div>
      )}
      {!isConfirmedInsurance && !loadingDoctors && selectedInsurance !== '' && (
        <button
          style={{
            marginTop: '10px',
            padding: '5px 10px',
            width: '60%',
            fontSize: '14px',
            background: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            opacity: selectedInsurance !== '' ? 1 : 0.5,
          }}
          onClick={handleConfirmInsurance}
        >
          Select
        </button>
      )}
    </div>
  );
};

export default InsurancePicker;