import React, { useState } from 'react';
import './AddDoctor.css';

const AddDoctor = ({ onClose, onDoctorAdded }) => {
    const [doctorName, setDoctorName] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(''); // To handle default images
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSaveClick = () => {
        if (!doctorName.trim()) {
            setError('Doctor name cannot be empty.');
            return;
        }

        if (!imageFile && !imageUrl) {
            setError('Please select an image or choose a default image.');
            return;
        }

        setLoading(true);
        setError('');
        const csrfToken = localStorage.getItem('csrfToken');

        const formData = new FormData();
        formData.append('doctor_name', doctorName);

        if (imageFile) {
            formData.append('image', imageFile);
        } else if (imageUrl) {
            formData.append('image_url', imageUrl);
        }
		const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/doctor/add_doctor`, {
            method: 'POST',
            headers: {
                'X-CSRF-Token': csrfToken
            },
            credentials: 'include', // This is key to include cookies for cross-origin
            body: formData
        })
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then((data) => {
            if (data.doctor) {
                onDoctorAdded(data.doctor);
            } else {
                setError(data.message || 'Error adding doctor.');
            }
        })
        .catch((error) => {
            setError('Network error: ' + error.message);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const handleCancelClick = () => {
        setDoctorName('');
        setImageFile(null);
        setImageUrl('');
        setError('');
        onClose();
    };

    const handleImageChange = (e) => {
        setImageFile(e.target.files[0]);
        setImageUrl('');
        setError('');
    };

    const handleDefaultImage1 = () => {
        setImageUrl('https://imgur.com/EUSrDSg.jpg');
        setImageFile(null);
        setError('');
    };

    const handleDefaultImage2 = () => {
        setImageUrl('https://i.imgur.com/7FwA1eX.webp');
        setImageFile(null);
        setError('');
    };

    const LoadingDots = () => (
        <span className="loading-dots">
            <span>.</span>
            <span>.</span>
            <span>.</span>
        </span>
    );

    return (
        <div style={{ padding: '20px', border: '1px solid #ccc', borderRadius: '8px', width: '300px' }}>
            <div>
                <div style={{ marginBottom: '10px' }}>
                    <label>
                        Name:
                        <input
                            type="text"
                            value={doctorName}
                            onChange={(e) => setDoctorName(e.target.value)}
                            style={{ width: '100%', padding: '8px', margin: '8px 0' }}
                            disabled={loading}
                        />
                    </label>
                </div>
                <div style={{ marginBottom: '10px' }}>
                    <label>
                        Image:
                        <input
                            type="file"
                            onChange={handleImageChange}
                            style={{ width: '100%', padding: '8px', margin: '8px 0' }}
                            disabled={loading}
                        />
                    </label>
                    {imageUrl && (
                        <div style={{ marginTop: '5px', fontSize: '12px', color: '#555' }}>
                            Selected image: {imageUrl}
                        </div>
                    )}
                </div>
                {error && <p style={{ color: 'red', marginBottom: '10px' }}>{error}</p>}
                <button onClick={handleSaveClick} style={{ padding: '8px 12px', fontSize: '14px', marginRight: '10px' }} disabled={loading}>
                    {loading ? 'Saving...' : 'Save'}
                </button>
                <button onClick={handleCancelClick} style={{ padding: '8px 12px', fontSize: '14px' }} disabled={loading}>
                    Cancel
                    {loading && <LoadingDots />}
                </button>
                <div style={{ margin: '10px 0px 10px 0px', display: 'flex', justifyContent: 'space-between' }}>
                    <button onClick={handleDefaultImage1} style={{ padding: '8px 12px', fontSize: '14px', display: 'flex', alignItems: 'center' }} disabled={loading}>
                        <img src="https://i.imgur.com/EUSrDSg.jpg" alt="Default 1" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                        Default 1
                    </button>
                    <button onClick={handleDefaultImage2} style={{ padding: '8px 12px', fontSize: '14px', display: 'flex', alignItems: 'center' }} disabled={loading}>
                        <img src="https://i.imgur.com/7FwA1eX.webp" alt="Default 2" style={{ width: '30px', height: '30px', marginRight: '8px' }} />
                        Default 2
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddDoctor;
