import React, { useState } from 'react';
import { NavLink, Link, useLocation } from 'react-router-dom';
import { navMenu } from '../data';
import './navHelpers/Navbar.css';

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [isSubmenuOpen, setIsSubmenuOpen] = useState(null);
    const location = useLocation();

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const submenuOpen = (menuName) => {
        isSubmenuOpen === menuName
            ? setIsSubmenuOpen(null)
            : setIsSubmenuOpen(menuName);
    };

    const resetMobileMenu = () => {
        setIsMobileMenuOpen(false);
        setIsSubmenuOpen(null);
    };

    return (
        <header className='main-header'>
            <div className='header-top'>
                <button
                    className='mobile-menu-button'
                    onClick={toggleMobileMenu}>
                    <i
                        className={
                            isMobileMenuOpen ? 'fa fa-times' : 'fa fa-bars'
                        }
                        aria-hidden='true'></i>
                </button>
                <div className='header-image-container'>
                    <Link to='/' className='logo-link'>
                        <img
                            src='https://i.imgur.com/3FM3KUI.webp'
                            alt='LA Ortho Associates'
                            className='header-image'
                            style={{ width: '300px', height: '120px' }}
                        />
                    </Link>
                </div>
            </div>
            <nav
                className={`navbar ${
                    isMobileMenuOpen ? 'mobile-menu-show' : ''
                }`}>
                {navMenu.map((item, index) => {
                    return (
                        <div
                            key={index}
                            className={`nav-link-container ${
                                item.menu === 'Scheduler App' ? 'scheduler-app' : ''
                            }`}>
                            <div className='link-items'>
                                <NavLink
                                    onClick={resetMobileMenu}
                                    key={item.menu}
                                    to={item.link}
                                    className={({ isActive }) =>
                                        isActive ? 'nav-link active' : 'nav-link'
                                    }>
                                    {item.menu}
                                </NavLink>
                                {item.menu === 'Scheduler App' && location.pathname !== '/' && (
                                    <div className='second-navbar'>
                                        <NavLink
                                            to='/schedulerApp'
                                            className={`nav-sublink ${
                                                location.pathname === '/schedulerApp' ? 'active' : ''
                                            }`}
                                            style={location.pathname === '/edit' ? { borderTop: '2px solid rgba(243, 74, 2, 0.8)' } : {}}
                                        >
                                            Search
                                        </NavLink>

                                        <NavLink
                                            to='/edit'
                                            style={location.pathname === '/edit' ? { borderTop: '2px solid rgba(243, 74, 2, 0.8)' } : {}}
                                            className={`nav-sublink ${
                                                location.pathname === '/edit' ? 'active' : ''
                                            }`}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'center' }}>
                                                Edit<i style={{ fontSize: '1rem', marginLeft: '5px', opacity: '0.5' }} className="fas fa-hard-hat"></i>
                                                <i style={{ fontSize: '1rem', opacity: '0.5', color: 'blue' }} className="fas fa-paint-roller"></i>    
                                            </div>
                                        </NavLink>
                                    </div>
                                )}
                                {item.subMenuItems && (
                                    <button
                                        className='mobile-toggle-submenu'
                                        onClick={() => submenuOpen(item.menu)}>
                                        {isSubmenuOpen === item.menu ? (
                                            <i className='fas fa-minus'></i>
                                        ) : (
                                            <i className='fas fa-plus'></i>
                                        )}
                                    </button>
                                )}
                            </div>
                        </div>
                    );
                })}
            </nav>
        </header>
    );
};

export default Navbar;