import React, { useState, useEffect } from 'react';
import NewBodilyPart from './modelUpdateComponents/NewBodilyPart';
import NewOffice from './modelUpdateComponents/NewOffice';
import NewInsurance from './modelUpdateComponents/NewInsurance';
import AgeGroupMenu from './modelUpdateComponents/AgeGroupMenu';
import OfficeGroupMenu from './modelUpdateComponents/helpers/OfficeGroupMenu';
import InsuranceGroupMenu from './modelUpdateComponents/helpers/InsuranceGroupMenu';
import BodyPartGroupMenu from './modelUpdateComponents/helpers/BodyPartGroupMenu';
import './EditBook.css';
import './Edit.css';

const EditBook = ({ title, lines, icon, showBook, toggleShowBook, openDropdown, setOpenDropdown, toggleDropdown, selectedDoctor, setSelectedDoctor, setEditBookArray, editBookArray, bodily_parts, offices, docName, setDoctors, setBodilyParts, setOffices, setInsurances, setOriginalDoctors, user, manualSetDoctors }) => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [showSaved, setShowSaved] = useState(false);
    const [key, setKey] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [selectedItemState, setSelectedItemState] = useState(null);
    const [locationsToSend, setLocationsToSend] = useState([]);
    const [bodilyPartsToSend, setBodilyPartsToSend] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [multipleAgeGroups, setMultipleAgeGroups] = useState(false);

    const isSuperAdmin = user && user.super_admin;
    let docIdSelectedDoctor = null;
    // console.log('selectedDoctor', selectedDoctor);
    useEffect(() => {
        // console.log('EditBook Props:', { title, lines, icon, showBook, toggleShowBook, openDropdown, setOpenDropdown, toggleDropdown, selectedDoctor, setSelectedDoctor, setEditBookArray, editBookArray, bodily_parts, offices, docName, setDoctors, setBodilyParts, setOffices, setInsurances, setOriginalDoctors, user, manualSetDoctors });
    }, [title, lines, icon, showBook, toggleShowBook, openDropdown, setOpenDropdown, toggleDropdown, selectedDoctor, setSelectedDoctor, setEditBookArray, editBookArray, bodily_parts, offices, docName, setDoctors, setBodilyParts, setOffices, setInsurances, setOriginalDoctors, user, manualSetDoctors]);

    // Log state
    useEffect(() => {
        // console.log('EditBook State:', { isUpdating, showSaved, key, showDeleteModal, deleting, selectedItemState, locationsToSend, bodilyPartsToSend, selectedItem, saving, loading, isSuperAdmin, docIdSelectedDoctor });
    }, [isUpdating, showSaved, key, showDeleteModal, deleting, selectedItemState, locationsToSend, bodilyPartsToSend, selectedItem, saving, loading, isSuperAdmin, docIdSelectedDoctor]);

    useEffect(() => {
        const count = selectedDoctor.doctor.age_groups.filter(group => group.covered).length;
        // console.log('count', count);
        setMultipleAgeGroups(count >= 2);
    }, [selectedDoctor.doctor.age_groups, setMultipleAgeGroups]);
    if (selectedDoctor) {
        docIdSelectedDoctor = selectedDoctor.doctor.id;
    }
    const handleItemClick = (name, destroy) => {
        setIsUpdating(true);
        const csrfToken = localStorage.getItem('csrfToken');
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/doctor/update_doctor`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            body: JSON.stringify({ doctor_id: selectedDoctor.doctor.id, title: title, name: name, destroy: destroy })
        })
        .then((response) => response.json())

        .then((responseJson) => {
            // console.log('response /update_doctor', responseJson);
            if (responseJson.message) {
                console.error('Error 1 /update_doctor');
            } else {
                const bookArray = [
                    {
                        title: 'Age Groups',
                        lines: responseJson.doctor.age_groups,
                        icon: 'fa-calendar-alt'
                    },
                    {
                        title: 'Body Parts',
                        lines: responseJson.doctor.bodily_parts,
                        icon: 'fa-universal-access'
                    },
                    {
                        title: 'Insurances',
                        lines: responseJson.doctor.insurances,
                        icon: 'fa-file-contract'
                    },
                    {
                        title: 'Offices',
                        lines: responseJson.doctor.offices,
                        icon: 'fa-building'
                    }
                ]
                const forManualDoctors = responseJson.doctor.doctors_scheduler;
                const updatedBookArray = editBookArray.map((book) => {
                    if (book.title === 'Age Groups') {
                        const updatedLines = book.lines.map((line) => {
                            if (selectedDoctor.doctor.age_groups.some(group => group.name === line.name)) {
                                const ageGroup = responseJson.doctor.age_groups.find((ag) => ag.name === line.name);
                                if (ageGroup) {
                                    return {
                                        ...line,
                                        relation: ageGroup.relation,
                                        locations: ageGroup.relation.locations,
                                        body_parts: ageGroup.relation.body_parts,
                                        covered: true
                                    };
                                } else {
                                    return {
                                        ...line,
                                        relation: null,
                                        locations: null,
                                        body_parts: null,
                                        covered: false
                                    };
                                }
                            }
                            if (line.relation) {
                                return {
                                    ...line,
                                    relation: {
                                        ...line.relation,
                                    },
                                    locations: line.relation.locations,
                                    body_parts: line.relation.body_parts,
                                    covered: true
                                };
                            } else {
                                return line;
                            }

                        });
                        return {
                            ...book,
                            lines: updatedLines
                        };
                    } else if (book.title === 'Body Parts') {
                            const updatedLines = book.lines.map((line) => {
                                const bodily_part = responseJson.doctor.bodily_parts.find((ag) => ag.name === line.name);
                                if (bodily_part) {
                                    return {
                                        ...line,
                                        covered: true,
                                        
                                    };
                                }
                                return {
                                    ...line,
                                    covered: false,
                                    
                                };
                            });

                            responseJson.doctor.bodily_parts.forEach((bodily_part) => {
                                const existingLine = updatedLines.find((line) => line.name === bodily_part.name);

                                if (!existingLine) {
                                    updatedLines.push({
                                        name: bodily_part.name,
                                        id: bodily_part.id,
                                        covered: true
    
                                    });
                                }
                            });
                            
                            return {
                                ...book,
                                lines: updatedLines
                            };


                    } else if (book.title === 'Insurances') {
                        const updatedLines = book.lines.map((line) => {
                            const insurance = responseJson.doctor.insurances.find((ag) => ag.name === line.name);
                            if (insurance) {
                                return {
                                    ...line,
                                    covered: true,
                                    
                                };
                            }
                            return {
                                ...line,
                                covered: false,
                                
                            };
                        });                        
                        responseJson.doctor.insurances.forEach((insurance) => {
                            const existingLine = updatedLines.find((line) => line.name === insurance.name);
                            if (!existingLine) {
                                updatedLines.push({
                                    name: insurance.name,
                                    id: insurance.id,
                                    covered: true

                                });
                            }
                        });
                        
                        return {
                            ...book,
                            lines: updatedLines
                        };
                    } else if (book.title === 'Offices') {
                        const updatedLines = book.lines.map((line) => {
                            const office = responseJson.doctor.offices.find((ag) => ag.name === line.name);
                            if (office) {
                                return {
                                    ...line,
                                    covered: true,
                                    
                                };
                            }
                            return {
                                ...line,
                                covered: false,
                                
                            };
                        });
                        responseJson.doctor.offices.forEach((office) => {
                            const existingLine = updatedLines.find((line) => line.name === office.name);

                            if (!existingLine) {
                                updatedLines.push({
                                    name: office.name,
                                    id: office.id,
                                    covered: true

                                });
                            }
                        });
                        
                        return {
                            ...book,
                            lines: updatedLines
                        };
                    }
                    return book;
                });
                setEditBookArray(updatedBookArray);
                setLocationsToSend([]);
                setBodilyPartsToSend([]);
                manualSetDoctors(forManualDoctors);
                setShowSaved(true); // Show the "Saved" message
                setTimeout(() => {
                    setShowSaved(false); // Hide the "Saved" message after 1 second
                }, 1500);
            }
                
            })
        .catch(error => {
            console.error('Error:', error);
        })
        
        .finally(() => {
            setIsUpdating(false);
        });
    };
    if (isUpdating) {
        return (
            <div style={{ width: '85vw', margin: "30px auto" }} className="edit-book">
                <div className="clock-arm-animation"></div>
            </div>
        );
    }

    const handleDeleteModal = (selectedItem) => {
        setShowDeleteModal(true);
        setSelectedItemState(selectedItem);
    };
    const confirmDelete = () => {
        setShowDeleteModal(false);
    };
    const handleDelete = () => {
        setDeleting(true);
        setLoading(true);
        const dataToSend = {
            doctor_name: docName,
            ageGroupId: selectedItemState.relation.age_group_id,
            doctorAgeGroupRelationId: selectedItemState.relation.id
        };
        const csrfToken = localStorage.getItem('csrfToken');
        const baseUrl = process.env.REACT_APP_API_BASE_URL;

        let endpoint = `${baseUrl}/doctor/delete_age_group`;

        if (multipleAgeGroups) {
            endpoint = `${baseUrl}/age_groups/delete_age_group_multiple_groups`;
        }
        
        fetch(endpoint, {
            method: 'DELETE',
            body: JSON.stringify({ data: dataToSend }),
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            }
        })
        
        .then(response => response.json())
        .then(data => {
            setDeleting(false);
            setShowDeleteModal(false);
            if (multipleAgeGroups) {
                // console.log('response /delete_age_group_multiple_groups', data);
            } else {
                // console.log('response /delete_age_group', data);
            }
            const bookArray = [
                {
                    title: 'Age Groups',
                    lines: data.doctor.age_groups,
                    icon: 'fa-calendar-alt'
                },
                {
                    title: 'Body Parts',
                    lines: data.doctor.bodily_parts,
                    icon: 'fa-universal-access'
                },
                {
                    title: 'Insurances',
                    lines: data.doctor.insurances,
                    icon: 'fa-file-contract'
                },
                {
                    title: 'Offices',
                    lines: data.doctor.offices,
                    icon: 'fa-building'
                }
            ]
            setSaving(false);
            setEditBookArray(null);
            setSelectedItem(null); 
            let updatedBookArray = null;
            updatedBookArray = editBookArray.map((book) => {
                if (book.title === 'Age Groups') {
                    const updatedLines = book.lines.map((line) => {
                        if (line.name === selectedItemState.name) {
                            const ageGroup = data.doctor.age_groups.find((ag) => ag.name === line.name);
                            if (ageGroup) {
                                return {
                                    ...line,
                                    relation: null,
                                    covered: false
                                };
                            } else {
                                return {
                                    ...line,
                                    relation: null,
                                    covered: false
                                };
                            }
                        }
                        if (line.relation) {
                            return {
                                ...line,
                                relation: {
                                    ...line.relation,
                                },
                                locations: line.relation.locations,
                                body_parts: line.relation.body_parts,
                                covered: true
                            };
                        } else {
                            return line;
                        }
                    });
                    return {
                        ...book,
                        lines: updatedLines
                    };
                } else if (book.title === 'Body Parts') {
                    let updatedLines = null;
                    updatedLines = book.lines.map((line, i) => {
                        const bodily_part = data.doctor.bodily_parts.find((ag) => ag.name === line.name);
                        // make this only go if updatedlines doesnt already have that name
                        if (!bodily_part) {
                            return {
                                name: line.name,
                                covered: false
                            };
                        } else if (updatedLines && !updatedLines.includes(bodily_part)) {
                            return {
                                name: bodily_part.name,
                                covered: bodily_part.covered
                            };
                        } else if (updatedLines === null) {
                            return {
                                name: bodily_part.name,
                                covered: bodily_part.covered
                            };
                        } 
                    });
                    return {
                        ...book,
                        lines: updatedLines
                    };
                } else if (book.title === 'Insurances') {
                    let updatedLines = null;
                    updatedLines = book.lines.map((line, i) => {
                        const insurance = data.doctor.insurances.find((ag) => ag.name === line.name);
                        // make this only go if updatedlines doesnt already have that name
                        if (!insurance) {
                            return {
                                name: line.name,
                                covered: false
                            };
                        } else if (updatedLines && !updatedLines.includes(insurance)) {
                            return {
                                name: insurance.name,
                                covered: insurance.covered
                            };
                        } else if (updatedLines === null) {
                            return {
                                name: insurance.name,
                                covered: insurance.covered
                            };
                        } 
                    });
                    return {
                        ...book,
                        lines: updatedLines
                    };
                } else if (book.title === 'Offices') {
                    let updatedLines = null;
                    updatedLines = book.lines.map((line, i) => {
                        const office = data.doctor.offices.find((ag) => ag.name === line.name);
                        // make this only go if updatedlines doesnt already have that name
                        if (!office) { 
                            return {
                                name: line.name,
                                covered: false
                            };
                         } else if (updatedLines && !updatedLines.includes(office)) {
                                return {
                                    name: office.name,
                                    covered: office.covered
                                };
                        } else if (updatedLines === null) {
                            return {
                                name: office.name,
                                covered: office.covered
                            };
                        } 
                    });
                    return {
                        ...book,
                        lines: updatedLines
                    };
                }
                return book;
            });
            const updatedSelectedItem = data.doctor.age_groups.find((ag) => ag.name === selectedItemState.name);

            if (updatedSelectedItem) {
                setSelectedItemState(null); // Clear selectedItem if the group is found
            }
            
            const forManualDoctors = data.doctor.doctors_scheduler;
            setEditBookArray(updatedBookArray);
            setShowSaved(true); // Show the "Saved" message
            setLoading(false);
            setTimeout(() => {
                setShowSaved(false); // Hide the "Saved" message after 1 second
            }, 1500);
        })
        .catch(error => {
            setDeleting(false);
            setShowDeleteModal(false);
            console.error('Error /delete_age_group', error);
        })

    }
    const cancelDelete = () => {
        setShowDeleteModal(false);
        setDeleting(false);
        setSelectedItemState(null);
    };

    return (
        <div className="edit-book" key={key} style={{ display: (showBook !== null && showBook !== title) ? 'none' : 'flex' }}>
            {showDeleteModal && (
                <div style={{ display: 'flex', height: '100vh', left: '-1%', top: '-76%', width: '100vw', background: 'black', zIndex: '30', position: 'absolute', alignItems: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <div>
                            <h2>Are you sure you want to delete?</h2>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <button style={{
                                display: 'flex',
                                margin: '0px 15px 0px 0px',
                                fontSize: '2rem'
                            }} onClick={handleDelete}>Yes</button>
                            <button style={{
                                display: 'flex',
                                margin: '0px 0px 0px 15px',
                                fontSize: '2rem'
                            }} onClick={cancelDelete}>No</button>
                        </div>
                    </div>
                </div>
            )}
            <div onClick={() => toggleDropdown(title)} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <i style={{ margin: '10px 5px 10px 5px' }} className={`fas ${icon} fa-2x`}></i>
                <div style={{ margin: '10px 5px 10px 5px', fontSize: '1rem' }}>
                    {title}
                </div>
            </div>
            {showSaved && (
                <div className="saved-message">Saved</div>
            )}

            {openDropdown && (
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>



                    {title === 'Age Groups' ? (
                        <AgeGroupMenu
                        title={title}
                        lines={lines}
                        icon={icon}
                        showBook={showBook}
                        toggleShowBook={toggleShowBook}
                        openDropdown={openDropdown}
                        setOpenDropdown={setOpenDropdown}
                        toggleDropdown={toggleDropdown}
                        loading={loading}
                        loadingEditBook={loading}
                        setLoading={setLoading}
                        setLoadingEditBook={setLoading}
                        selectedDoctor={selectedDoctor}
                        setSelectedDoctor={setSelectedDoctor}
                        setEditBookArray={setEditBookArray}
                        editBookArray={editBookArray}
                        setShowSaved={setShowSaved}
                        bodily_parts={bodily_parts}
                        offices={offices}
                        docName={docName}
                        setDoctors={setDoctors}
                        setBodilyParts={setBodilyParts}
                        setOffices={setOffices}
                        setInsurances={setInsurances}
                        setOriginalDoctors={setOriginalDoctors}
                        setKey={setKey}
                        manualSetDoctors={manualSetDoctors}
                        showDeleteModal={showDeleteModal}
                        setShowDeleteModal={setShowDeleteModal}
                        handleDeleteModal={handleDeleteModal}
                        deleting={deleting}
                    />
                    ) : title === 'Insurances' ? (
                        <InsuranceGroupMenu
                            title={title}
                            lines={lines}
                            icon={icon}
                            showBook={showBook}
                            toggleShowBook={toggleShowBook}
                            openDropdown={openDropdown}
                            setOpenDropdown={setOpenDropdown}
                            toggleDropdown={toggleDropdown}
                            loading={loading}
                            selectedDoctor={selectedDoctor}
                            setSelectedDoctor={setSelectedDoctor}
                            setEditBookArray={setEditBookArray}
                            editBookArray={editBookArray}
                            setShowSaved={setShowSaved}
                            bodily_parts={bodily_parts}
                            offices={offices}
                            docName={docName}
                            setDoctors={setDoctors}
                            setBodilyParts={setBodilyParts}
                            setOffices={setOffices}
                            setInsurances={setInsurances}
                            setOriginalDoctors={setOriginalDoctors}
                            setKey={setKey}
                            manualSetDoctors={manualSetDoctors}
                            showDeleteModal={showDeleteModal}
                            setShowDeleteModal={setShowDeleteModal}
                            handleDeleteModal={handleDeleteModal}
                            deleting={deleting}
                            handleSaveInsurances={handleItemClick}
                        />
                    ) : title === 'Body Parts' ? (
                                <BodyPartGroupMenu
                                    title={title}
                                    lines={lines}
                                    icon={icon}
                                    showBook={showBook}
                                    toggleShowBook={toggleShowBook}
                                    openDropdown={openDropdown}
                                    setOpenDropdown={setOpenDropdown}
                                    toggleDropdown={toggleDropdown}
                                    loading={loading}
                                    selectedDoctor={selectedDoctor}
                                    setSelectedDoctor={setSelectedDoctor}
                                    setEditBookArray={setEditBookArray}
                                    editBookArray={editBookArray}
                                    setShowSaved={setShowSaved}
                                    bodily_parts={bodily_parts}
                                    offices={offices}
                                    docName={docName}
                                    setDoctors={setDoctors}
                                    setBodilyParts={setBodilyParts}
                                    setOffices={setOffices}
                                    setInsurances={setInsurances}
                                    setOriginalDoctors={setOriginalDoctors}
                                    setKey={setKey}
                                    manualSetDoctors={manualSetDoctors}
                                    showDeleteModal={showDeleteModal}
                                    setShowDeleteModal={setShowDeleteModal}
                                    handleDeleteModal={handleDeleteModal}
                                    deleting={deleting}
                                    handleSaveBodyParts={handleItemClick}
                                />
                            ) : (
                                <OfficeGroupMenu
                                title={title}
                                lines={lines}
                                icon={icon}
                                showBook={showBook}
                                toggleShowBook={toggleShowBook}
                                openDropdown={openDropdown}
                                setOpenDropdown={setOpenDropdown}
                                toggleDropdown={toggleDropdown}
                                loading={loading}
                                selectedDoctor={selectedDoctor}
                                setSelectedDoctor={setSelectedDoctor}
                                setEditBookArray={setEditBookArray}
                                editBookArray={editBookArray}
                                setShowSaved={setShowSaved}
                                bodily_parts={bodily_parts}
                                offices={offices}
                                docName={docName}
                                setDoctors={setDoctors}
                                setBodilyParts={setBodilyParts}
                                setOffices={setOffices}
                                setInsurances={setInsurances}
                                setOriginalDoctors={setOriginalDoctors}
                                setKey={setKey}
                                manualSetDoctors={manualSetDoctors}
                                showDeleteModal={showDeleteModal}
                                setShowDeleteModal={setShowDeleteModal}
                                handleDeleteModal={handleDeleteModal}
                                deleting={deleting}
                                handleSaveLocations={handleItemClick}
                            />
                    )}

                    {title === 'Body Parts' && (
                        <NewBodilyPart setShowSaved={setShowSaved} selectedDoctor={selectedDoctor} setEditBookArray={setEditBookArray} editBookArray={editBookArray} manualSetDoctors={manualSetDoctors} setSelectedItem={setSelectedItemState} />
                    )}
                    {title === 'Offices' && (
                        <NewOffice setShowSaved={setShowSaved} selectedDoctor={selectedDoctor} setEditBookArray={setEditBookArray} editBookArray={editBookArray} manualSetDoctors={manualSetDoctors} setSelectedItem={setSelectedItemState} />
                    )}
                    {title === 'Insurances' && (
                        <NewInsurance setShowSaved={setShowSaved} selectedDoctor={selectedDoctor} setEditBookArray={setEditBookArray} editBookArray={editBookArray} manualSetDoctors={manualSetDoctors} setSelectedItem={setSelectedItemState} />
                    )}
                </div>
            )}

        </div>
    );
};

export default EditBook;
