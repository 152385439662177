import React, { useState, useEffect, useRef } from 'react';
import './Edit.css';
import EditBook from './EditBook'; // Import the EditBook component
import EditDoctor from './EditDoctor'; // Import the EditDoctor component
import AddDoctor from './AddDoctor'; // Import the AddDoctor component

const Edit = (props) => {
    const { user, doctors, setDoctors } = props;
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredDoctors, setFilteredDoctors] = useState('');
    const [showEditBook, setShowEditBook] = useState(null);
    const [editBookArray, setEditBookArray] = useState(null);
    const [showEditDoctor, setShowEditDoctor] = useState(false); // State to control showing the EditDoctor component
    const isAdmin = user && user.admin;
    const searchInputRef = useRef(null);
    const [autoCompleteSuggestion, setAutoCompleteSuggestion] = useState('');
    const [showBook, setShowBook] = useState(null);
    const [openDropdown, setOpenDropdown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showAddDoctor, setShowAddDoctor] = useState(false);

    const handleAddDoctorClick = () => {
        setShowAddDoctor(true);
    };

    const handleCloseAddDoctor = () => {
        setShowAddDoctor(false);
    };

    const handleDoctorAdded = (newDoctor) => {
        const newDoctorWithTimestamp = {
            ...newDoctor,
            url: `${newDoctor.url}?v=${new Date().getTime()}`, // Add timestamp to avoid caching issues
        };

        const updatedDoctors = [...doctors, newDoctorWithTimestamp]; // Add the new doctor to the doctors array

        setDoctors(updatedDoctors); // Update the doctors state with the new data
        setShowAddDoctor(false); // Close the AddDoctor component

        // Refresh the page to reflect the new state
        window.location.reload();
    };


    useEffect(() => {
        // console.log('Edit Props:', { user, doctors });
    }, [user, doctors]);

    // Log state
    useEffect(() => {
        // console.log('Edit State:', { selectedDoctor, searchTerm, filteredDoctors, showEditBook, editBookArray, isAdmin, autoCompleteSuggestion, showBook, openDropdown, loading });
    }, [selectedDoctor, searchTerm, filteredDoctors, showEditBook, editBookArray, isAdmin, autoCompleteSuggestion, showBook, openDropdown, loading]);

    const handleEditDoctorClick = () => {
        setShowEditDoctor(!showEditDoctor); // Toggle the EditDoctor component visibility
    };

    const handleCloseEditDoctor = () => {
        setShowEditDoctor(false);
    };

    const handleDoctorUpdated = (updatedDoctor) => {
        // console.log('doctors before', doctors);
        // console.log('updatedDoctor', updatedDoctor);
        const updatedDoctors = doctors.map((doctor) => {
            // console.log('doctorrrrr', doctor);
            if (doctor.name === updatedDoctor.oldName) {
                return {
                    ...doctor, // Keep all other properties unchanged
                    name: updatedDoctor.newDoctor.name,
                    url: updatedDoctor.newDoctor.image
                };
            }
            return doctor;
        });
    
        setDoctors(updatedDoctors); // Update the doctors state with the new data
        // console.log('doctors after', updatedDoctors);
    };
    

    const toggleDropdown = (title) => {
        if (openDropdown) {
            setOpenDropdown(false);
            toggleShowBook(null);
        } else {
            setOpenDropdown(true);
            toggleShowBook(title);
        }
    };
    useEffect(() => {
        const filtered = doctors.filter((doctor) => {
            // console.log('split below doctor', doctor);
            const firstName = doctor.name.split(' ')[0].toLowerCase();
            const lastName = doctor.name.split(' ')[1]?.toLowerCase() || '';
            return firstName.startsWith(searchTerm.toLowerCase()) || lastName.startsWith(searchTerm.toLowerCase()) || doctor.name.toLowerCase().includes(searchTerm.toLowerCase());
        }).sort((a, b) => {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            const firstNameA = nameA.split(' ')[0].toLowerCase();
            const firstNameB = nameB.split(' ')[0].toLowerCase();
            const lastNameA = nameA.split(' ')[1]?.toLowerCase() || '';
            const lastNameB = nameB.split(' ')[1]?.toLowerCase() || '';
            if ((firstNameA.startsWith(searchTerm.toLowerCase()) && !firstNameB.startsWith(searchTerm.toLowerCase())) ||
                (firstNameA.startsWith(searchTerm.toLowerCase()) && firstNameB.startsWith(searchTerm.toLowerCase()) && lastNameA.startsWith(searchTerm.toLowerCase()) && !lastNameB.startsWith(searchTerm.toLowerCase())) ||
                (firstNameA.startsWith(searchTerm.toLowerCase()) && firstNameB.startsWith(searchTerm.toLowerCase()) && !lastNameB.startsWith(searchTerm.toLowerCase()))) {
                return -1;
            } else if ((firstNameB.startsWith(searchTerm.toLowerCase()) && !firstNameA.startsWith(searchTerm.toLowerCase())) ||
                       (firstNameB.startsWith(searchTerm.toLowerCase()) && firstNameA.startsWith(searchTerm.toLowerCase()) && lastNameB.startsWith(searchTerm.toLowerCase()) && !lastNameA.startsWith(searchTerm.toLowerCase())) ||
                       (firstNameB.startsWith(searchTerm.toLowerCase()) && firstNameA.startsWith(searchTerm.toLowerCase()) && !lastNameA.startsWith(searchTerm.toLowerCase()))) {
                return 1;
            } else {
                return nameA.localeCompare(nameB);
            }
        });
        setFilteredDoctors(filtered);
    }, [doctors, searchTerm]);
    useEffect(() => {
        if (searchInputRef.current) {
            searchInputRef.current.focus();
        }
    }, []);
    useEffect(() => {
        if (selectedDoctor) {
            setShowEditBook(true);
        }
    }, []);
    useEffect(() => {
        if (!selectedDoctor) {
            setShowEditBook(false);
        }
    }, []);
    const toggleShowBook = (book) => {
        setShowBook(book);
    };
    const fetchDoctor = (doctorName) => {
        setLoading(true);
		const csrfToken = localStorage.getItem('csrfToken');
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        fetch(`${baseUrl}/doctor/pull_doctor`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            body: JSON.stringify({ doctor_name: doctorName })
        })
            .then((response) => response.json())
            .then((data) => {
                // console.log('response /pull_doctor', data);
                setSelectedDoctor(data);
                const bookArray = [
                    {
                        title: 'Age Groups',
                        lines: data.doctor.age_groups,
                        icon: 'fa-calendar-alt'
                    },
                    {
                        title: 'Body Parts',
                        lines: data.doctor.bodily_parts,
                        icon: 'fa-universal-access'
                    },
                    {
                        title: 'Insurances',
                        lines: data.doctor.insurances,
                        icon: 'fa-file-contract'
                    },
                    {
                        title: 'Offices',
                        lines: data.doctor.offices,
                        icon: 'fa-building'
                    }
                ]
                setEditBookArray(bookArray);
            })
            .catch((error) => {
                console.error('Error /pull_doctor', error);
            })
            .finally(() => {
                setLoading(false);
            });
        };
    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        setSelectedDoctor(null);
        setEditBookArray(null);
        toggleShowBook(null);
        setShowBook(null);
        setFilteredDoctors(doctors);
        setOpenDropdown(false);
        const filtered = doctors.filter((doctor) => {
            const firstName = doctor.name.split(' ')[0].toLowerCase();
            const lastName = doctor.name.split(' ')[1]?.toLowerCase() || '';
            return firstName.startsWith(value) || lastName.startsWith(value);
        });
        if (filtered.length === 0) {
            const includesFiltered = doctors.filter((doctor) => {
                const firstName = doctor.name.split(' ')[0].toLowerCase();
                const lastName = doctor.name.split(' ')[1]?.toLowerCase() || '';
                return firstName.includes(value) || lastName.includes(value);
            });
            setFilteredDoctors(includesFiltered);
        } else {
            setFilteredDoctors(filtered);
        }
        setShowEditBook(true);
        if (filtered.length > 0) {
            setAutoCompleteSuggestion(filtered[0].name);
        } else {
            setAutoCompleteSuggestion('');
        }
    };
    const handleDoctorClick = (doctorName) => {
        if (!selectedDoctor) {
            setSearchTerm(doctorName);
            fetchDoctor(doctorName);
            setOpenDropdown(false);
            const removedItem = document.querySelector(
                `.doctor-item.${doctorName.replace(/\s/g, '-')}`
            );
            if (removedItem) {
                removedItem.classList.add('slide-out-left');
                setTimeout(() => {
                    removedItem.remove();
                }, 500); // Match the animation duration
            }
        }
    };
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && filteredDoctors.length > 0) {
            fetchDoctor(filteredDoctors[0].name);
            const doctorFound = [filteredDoctors[0]];
            setFilteredDoctors(doctorFound);
        } else if (event.key === 'Tab' && filteredDoctors.length > 0) {
            event.preventDefault();
            setSearchTerm(filteredDoctors[0].name);
        }
    };
    const handleBackspace = (event) => {
        if (event.key === 'Backspace') {
            setSearchTerm((prevSearchTerm) => prevSearchTerm.slice(0, -1));
            setSelectedDoctor(null);
            setEditBookArray(null);
            toggleShowBook(null);
            setShowBook(null);
            setFilteredDoctors(doctors);
            setOpenDropdown(false);
        }
    };
    const handleBackButton = () => {
        setSearchTerm('');
        setSelectedDoctor(null);
        setEditBookArray(null);
        toggleShowBook(null);
        setShowBook(null);
        setFilteredDoctors(doctors);
        setOpenDropdown(false);
        setShowEditDoctor(false); // Ensure that the EditDoctor component is hidden
    };

    return (
        <div className={`update-div-one ${!isAdmin ? 'disabled' : ''}`}>
            {!isAdmin && <div class={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100vw', textAlight: 'center', opacity: '1 !important' }} className="disabled-overlay">Admin Access Required</div>}
            {selectedDoctor && (
                <button onClick={handleBackButton} className="button-click" style={{ margin: '0px 0px 20px 10px', padding: '2px 15px 2px 15px', fontSize: '1rem', borderRadius: '5px', cursor: 'pointer' }}>Back</button>
            )}
            <div className="doctors-list">
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '5px', width: '100%' }} className="doctors-column">
                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: '40px' }}>
                        <h4 style={{ padding: '4px 20px 4px 4px' }}>Doctors:</h4>
                        <div className="search-box">
                            <input
                                ref={searchInputRef}
                                type="text"
                                value={searchTerm}
                                onKeyPress={handleKeyPress}
                                onKeyDown={handleBackspace} // Add this line to handle the Backspace key press
                                style={{ height: '38px', fontSize: '1.5rem', width: '115%', margin: '0px -133px 0px 0px', padding: '10px 10px 10px 10px' }}
                                placeholder='Search...'
                                onChange={handleSearchChange}
                                onFocus={(e) => e.target.placeholder = ''}
                                onBlur={(e) => e.target.placeholder = 'Search...'}
                            />
                        </div>
                        {user?.admin && (
                            <button onClick={handleAddDoctorClick} style={{ height: "min-content", padding: '9px 12px', margin: "0 0 0 50px", fontSize: '14px', cursor: 'pointer' }}>
                                Add Doctor
                            </button>
                        )}
                    </div>
                    {showAddDoctor && (
                        <AddDoctor
                            onClose={handleCloseAddDoctor}
                            onDoctorAdded={handleDoctorAdded}
                        />
                    )}
                    <ul className="doctor-ul">
                        {filteredDoctors && filteredDoctors.map((doctor, index) => (
                            <li
                                className={`doctor-item ${
                                    index === 0 ? 'highlighted' : ''
                                } ${
                                    filteredDoctors.includes(doctor) ? '' : ''
                                }`}
                                key={doctor.name}
                                onClick={() => handleDoctorClick(doctor.name)}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', width: 'max-content' }}>
                                    <img
                                        src={doctor.url}
                                        alt='Doc Logo'
                                        style={{
                                            width: index !== 0 ? '10px' : '125px',
                                            height: index !== 0 ? '10px' : '160px',
                                            padding: index !== 0 ? '1px 2px 1px 1px' : '8px 0px 8px 8px'
                                        }}
                                    />
                                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', padding: index !== 0 ? '1px 2px 1px 1px' : '0px 5px 0px 5px' }}>
                                        {doctor.name}
                                        <p style={{
                                            color: 'springgreen',
                                            margin: '-15px 0px 0px 5px',
                                            padding: '0px 0px 0px 0px',
                                            alignSelf: 'flex-start',
                                            justifySelf: 'flex-end',
                                            fontFamily: 'sans-serif',
                                            fontWeight: '100',
                                            opacity: '0.8',
                                            textShadow: 'none',
                                            letterSpacing: '2px',
                                            fontSize: index === 0 ? '0.8rem' : '0.2rem' // Change the font size based on the index
                                        }}>
                                            active
                                        </p>
                                        {props.user && props.user.admin && selectedDoctor && (
                                            <p
                                                className="edit"
                                                onClick={handleEditDoctorClick}
                                                style={{
                                                    color: 'blue',
                                                    textDecoration: 'none',
                                                    textShadow: 'none',
                                                    cursor: 'pointer',
                                                    fontSize: '1.3rem',
                                                    margin: '-15px 0px 0px 5px',
                                                    padding: '0px 0px 0px 0px',
                                                    alignSelf: 'flex-start'
                                                }}
                                            >
                                                edit
                                            </p>
                                        )}
                                    </div>
                                </div>
                                {showEditDoctor ? (
                                    <EditDoctor
                                        doctor={doctor}
                                        onClose={handleCloseEditDoctor}
                                        docId={selectedDoctor.doctor.id}
                                        onDoctorUpdated={handleDoctorUpdated} // Pass the callback function
                                    />
                                ) : (
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around' }}>
                                        <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'flex-start', height: '100%' }}>
                                            {editBookArray ? (
                                                <div style={{ display: 'flex', width: '81vw', flexWrap: 'wrap', alignItems: 'flex-start' }}>
                                                    {editBookArray.map((book, index) => (
                                                        <div key={index} 
                                                            style={{
                                                                display: showBook && showBook !== book.title ? 'none' : 'flex',
                                                                width: '100%',
                                                                justifySelf: 'flex-start'
                                                            }}
                                                        >
                                                            {book.title && <EditBook manualSetDoctors={props.manualSetDoctors} showBook={showBook} toggleShowBook={toggleShowBook} title={book.title} lines={book.lines} icon={book.icon} openDropdown={openDropdown} setOpenDropdown={setOpenDropdown} toggleDropdown={toggleDropdown} selectedDoctor={selectedDoctor} setSelectedDoctor={setSelectedDoctor} setEditBookArray={setEditBookArray} editBookArray={editBookArray} user={user} bodily_parts={props.bodily_parts} offices={props.offices} docName={filteredDoctors[0].name} setDoctors={props.setDoctors} setBodilyParts={props.setBodilyParts} setOffices={props.setOffices} setOriginalDoctors={props.setOriginalDoctors} setInsurances={props.setInsurances} />}
                                                        </div>
                                                    ))}
                                                </div>
                                            ) : (
                                                loading && <div className="clock-arm-animation"></div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Edit;
