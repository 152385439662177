import { Routes, Route, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Edit from './components/navHelpers/Edit';
import Error from './pages/Error';


import SchedulerApp from './pages/scheduler/SchedulerAppLayout';

// "proxy": "https://la-orthos-app-da82c790d223.herokuapp.com/",
// "proxy": "https://localhost:3000/",
function App() {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
        });
    }, [pathname]);
    const [isUserSignedIn, setIsUserSignedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [doctors, setDoctors] = useState(null);
    const [offices, setOffices] = useState(null);
    const [insurances, setInsurances] = useState(null);
    const [bodily_parts, setBodilyParts] = useState(null);
    const [originalDoctors, setOriginalDoctors] = useState(null);
    const [fetchingLoggedIn, setFetchingLoggedIn] = useState(false);
    if (!localStorage.getItem('csrfToken')) {
      const baseUrl = process.env.REACT_APP_API_BASE_URL;
    
      // fetch(`${baseUrl}/csrf_token`, {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json'
      //   }
      // })
      // .then(response => response.json())
      // .then(data => {
      //   // console.log('response /csrf_token', data);
      //   const csrfToken = data.csrf_token;
      //   localStorage.setItem('csrfToken', data.csrf_token);
      // })
      // .catch(error => {
      //   console.error('Error csrf_token', error);
      // });

      fetch(`${baseUrl}/csrf_token`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include' // ensure cookies are sent
      })
        .then(response => response.json())
        .then(data => {
          const csrfToken = data.csrf_token;
          localStorage.setItem('csrfToken', csrfToken);
        })
        .catch(error => {
          console.error('Error fetching CSRF token:', error);
        });
      

    }
    const manualSetDoctors = (doctors) => {
      setDoctors(doctors);
    }
    useEffect(() => {
      if (!user && !fetchingLoggedIn) {
        setFetchingLoggedIn(true);
        const baseUrl = process.env.REACT_APP_API_BASE_URL;

        fetch(`${baseUrl}/logged_in`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: 'include' // ensure cookies are sent
        })
        .then((res) => {
            if (res.ok) {
                res.json().then((response) => {
                    if (response.logged_in) {
                      // console.log('response /logged_in', response);
                      setFetchingLoggedIn(false);
                      setIsUserSignedIn(true);
                      setUser(response.user);
                      setDoctors(response.doctors);
                      const uniqueOffices = Array.from(new Set(response.doctors.flatMap(doctor => doctor.offices)));
                      setOffices(uniqueOffices);
                      setOriginalDoctors(response.doctors);
                      setInsurances(response.insurances);
                      setBodilyParts(response.bodily_parts);
                    }
                });
            }
        })
        .catch((error) => {
          console.log('Error logged_in', error);
          setFetchingLoggedIn(false);
        });
      }
  }, []);
  
        let csrfToken = '';
        if (csrfToken = localStorage.getItem('csrfToken')) {
          csrfToken = localStorage.getItem('csrfToken');
        }
        const handleLogout = () => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
          fetch(`${baseUrl}/logout`, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              'X-CSRF-Token': csrfToken
            },
          }).then((res) => {
            if (res.ok) {
              // console.log('response /logout', res);
              setIsUserSignedIn(false);
              setUser(null);
            }
          })
          .catch((error) => {
            console.log('Error logout', error);
          });
        };

        const resetDoctors = () => {
          setDoctors(originalDoctors);
      };
    return (
        <>
            <Navbar />
            <Routes>
              <Route index element={<Home
                  isUserSignedIn={isUserSignedIn}
                  handleLogout={handleLogout}
                  user={user}
                  setIsUserSignedIn={setIsUserSignedIn}
                  setUser={setUser}
                  doctors={doctors}
                  setDoctors={setDoctors}
                  insurances={insurances}
                  setInsurances={setInsurances}
                  bodily_parts={bodily_parts}
                  setBodilyParts={setBodilyParts}
                  originalDoctors={originalDoctors}
                  setOriginalDoctors={setOriginalDoctors}
                />}
                    />

                <Route path='schedulerApp' element={<SchedulerApp manualSetDoctors={manualSetDoctors} user={user} doctors={doctors} setDoctors={setDoctors} insurances={insurances} setInsurances={setInsurances} bodily_parts={bodily_parts} setBodilyParts={setBodilyParts} resetDoctors={resetDoctors} offices={offices} setOriginalDoctors={setOriginalDoctors} setOffices={setOffices}/>}>
                
                </Route>
                <Route path='edit' element={<SchedulerApp manualSetDoctors={manualSetDoctors} user={user} doctors={doctors} setDoctors={setDoctors} insurances={insurances} setInsurances={setInsurances} bodily_parts={bodily_parts} setBodilyParts={setBodilyParts} resetDoctors={resetDoctors} offices={offices} setOriginalDoctors={setOriginalDoctors} setOffices={setOffices} />}>
              
                </Route>
              
                <Route path='*' element={<Error />} />
            </Routes>
        </>
    );
}

export default App;
